import request from "@/plugins/request";

const baseUrl = "news";
function getActivityInfos(data) {
  return request({
    url: baseUrl + "/activity-infos",
    method: "POST",
    data
  });
}
function readActivityMsg(data) {
  return request({
    url: baseUrl + "/read",
    method: "POST",
    data
  });
}

export const newsApi = {
  getActivityInfos,
  readActivityMsg
};
