<template>
  <div>
    <div class="md">
      <div class="md-l"></div>
      <a class="lk" v-on:click="getMsg" href="javascript:">操作教學</a>
      <marquee-text
        :duration="50"
        :paused="paused"
        @mouseover="paused = true"
        @mouseout="paused = false"
      >
        <template v-if="!webMode">
          <span
            v-for="(val, key) in winmarqueesList"
            :key="key"
            :class="{ c1: key % 3 == 0, c2: key % 3 == 1, c3: key % 3 == 2 }"
          >
            {{ val.content }}
          </span>
        </template>
        <template v-else>
          <span
            style="font-size: 18px"
            v-for="(mar, key) in marqueesList"
            :key="key"
            >【重要公告】{{ mar.content }}</span
          >
        </template>
      </marquee-text>
      <!-- <marquee
        v-show="seoSettings.winmq_status"
        onmouseover="this.stop();"
        onmouseout="this.start();"
        behavior=""
        direction=""
        truespeed="true"
        :scrolldelay="seoSettings.winmq_carousel"
      >
        <template v-if="!webMode">
          <span
            v-for="(val, key) in winmarqueesList"
            :key="key"
            :class="{ c1: key % 3 == 0, c2: key % 3 == 1, c3: key % 3 == 2 }"
          >
            {{ val.content }}
          </span>
        </template>
        <template v-else>
          <span
            style="font-size: 18px"
            v-for="(mar, key) in marqueesList"
            :key="key"
            >【重要公告】{{ mar.content }}</span
          >
        </template>
      </marquee> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MarqueeText from "vue-marquee-text-component";

export default {
  name: "compMarquee",
  components: {
    MarqueeText,
  },
  data() {
    return {
      paused: false,
    };
  },
  props: {},
  computed: {
    ...mapGetters({
      webMode: "webMode",
    }),
    ...mapState({
      instructionList: (state) => state.website.instructionList,
      winmarqueesList: (state) => state.notice.winmarqueesList,
      marqueesList: (state) => state.notice.marqueesList,
      seoSettings: (state) => state.website.seoSettings,
    }),
  },
  methods: {
    async getWinmarquees(lottery_id) {
      await this.$store.dispatch("notice/getWinmarquees");
    },
    async getmarquees(lottery_id) {
      await this.$store.dispatch("notice/getmarquees");
    },
    async getInstructionList(lottery_id) {
      await this.$store.dispatch("website/getInstructionList");
    },
    getMsg() {
      let instructionCode = this.$route.matched[0].name;
      var data = this.instructionList.find((x) => x.code === instructionCode);
      if (data) {
        $.fancybox.open({
          src: this.$baseImgUrl + data.url,
          type: "iframe",
          opts: {},
        });
      } else {
        this.$snotify.info("本區無使用教學");
        return;
      }
    },
  },
  async mounted() {
    await this.getWinmarquees();
    await this.getmarquees();
    await this.getInstructionList();
  },
};
</script>

<style scoped>

</style>
