import request from "@/plugins/request";

const baseUrl = "marketing";
function getMarketingList(data) {
  return request({
    url: baseUrl + "/list",
    method: "POST",
    data
  });
}

export const marketingApi = {
  getMarketingList,
};
