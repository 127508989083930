import request from "@/plugins/request";

const baseUrl = "activity";
function getLottList(data) {
  return request({
    url: baseUrl + "/list",
    method: "POST",
    data
  });
}

function getDetail(data) {
  return request({
    url: baseUrl + "/detail",
    method: "POST",
    data
  });
}

function action(data) {
  return request({
    url: baseUrl + "/action",
    method: "POST",
    data
  });
}
function action10times(data) {
  return request({
    url: baseUrl + "/action",
    method: "POST",
    data
  });
}
function hotlist(data) {
  return request({
    url: baseUrl + "/hotlist",
    method: "POST",
    data
  });
}
function record(data) {
  return request({
    url: baseUrl + "/record",
    method: "POST",
    data
  });
}

export const activityApi = {
  getLottList,
  getDetail,
  action,
  action10times,
  hotlist,
  record
};
