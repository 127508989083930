import request from "@/plugins/request";

const baseUrl = "appsetting";
function getDownloadList() {
  return request({
    url: baseUrl + "/list",
    method: "POST",
  });
}

export const appSettingApi = {
  getDownloadList,
};
