import { getStorage, removeStorage } from '@/plugins/auth'
import * as $api from '@/lib/api'
import $router from '@/router'

const state = {
  autoLogout: false,
  loginState: false,
  userInfo: {}
}

const mutations = {
  setUserInfo: (state, data) => {
    state.userInfo = data
  },
  setAutoLogoutTrue: (state) => {
    state.autoLogout = true
  },
  setAutoLogoutFalse: (state) => {
    state.autoLogout = false
  }
}

const actions = {
  async register ({ commit, dispatch }, _d) {
    const data = await $api.memberApi.register(_d)
    return data.status
  },
  async forget ({ commit, dispatch }, _d) {
    const data = await $api.memberApi.forget(_d)
    return data.status
  },
  async shareView ({ commit, dispatch }, _d) {
    const data = await $api.memberApi.shareView(_d)
    return data.status
  },
  async login ({ commit, dispatch }, _d) {
    const data = await $api.memberApi.login(_d)
    if (data) {
      commit('setUserInfo', data.data)
      await dispatch(
        'app/vodMsg',
        { cont: data.data.name + '您好', ttl: '登入成功', type: 'info' },
        { root: true }
      )
      await dispatch('image/getLoginAdList', {}, { root: true })
      await commit('app/toggleLogin', {}, { root: true })
      if (data.data.sms == 0 || data.data.email == 0) {
        await $router.push({ name: 'memberAuth' })
      } else {
        await $router.push({ name: 'memberInfo' })
      }
    }
  },
  async logout ({ commit, dispatch }) {
    const token = sessionStorage.token ? sessionStorage.token : ''
    // const data = await $api.memberApi.logout({ token })
    if (await $api.memberApi.logout({ token })) {
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('wait')
      await commit('app/toggleLogin', {}, { root: true })
      await commit('setAutoLogoutFalse')
      await dispatch(
        'app/vodMsg',
        { cont: state.userInfo.name + '期待您下次蒞臨', ttl: '已將您登出', type: 'info' },
        { root: true }
      )
      $router.push({ name: 'home' })
    }
  },
  async getuser ({ commit, dispatch }) {
    const token = sessionStorage.token ? sessionStorage.token : ''

    if (token) {
      const data = await $api.memberApi.getuser({ token })
      if (data.status) {
        await commit('setUserInfo', data.data)
      } else {
        await sessionStorage.removeItem('token')
        await commit('setAutoLogoutTrue', data.data)
      }
      await commit('app/toggleLogin', {}, { root: true })
      return data.status
    } else {
      return false
    }
  },
  async edituser ({ commit, dispatch }, _d) {
    _d = await dispatch('app/addToken', _d, { root: true })
    const data = await $api.memberApi.edituser(_d)
    return data.status
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
