<template>
  <div class="nr">
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <div class="l-nav">
          <router-link class="" to="/cbuy/list">購買項目 <i>></i></router-link>
          <router-link class="act" to="/cbuy/his">交易明細 <i>></i></router-link>
        </div>
        <div class="r-content" style="height:700px;overflow-y: auto">
          <h2>交易明細<a :href="seoSettings.invoice_url" target="_blank">發票查詢</a></h2>
          <table>
            <thead>
              <tr>
                <th>訂單日期</th>
                <th>金額</th>
                <th>付款方式</th>
                <th>付款狀況</th>
                <th>繳款日期</th>
                <th>發票號碼</th>
                <th>隨機碼</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in rechargeOrder" :key="index">
                <td>{{ item.order_at }}</td>
                <td>${{ Number(item.money) }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.pay_status }}</td>
                <td>{{ item.pay_at }}</td>
                <td>{{ item.ticket_number }}</td>
                <td>{{ item.random_code }}</td>
              </tr>
            </tbody>
          </table>
          <CompPager
            @evtpagerchg="vodGetPage"
            :int-per-page="page.pagesize"
            :int-tol-cnt="pageData.allnums"
            :int-cur-page="page.currentPage"
            ref="pager"
          ></CompPager>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompPager: require("@/components/parts/pager").default
  },
  computed: {
    ...mapState({
      seoSettings: state => state.website.seoSettings,
      rechargeOrder: state => state.recharge.rechargeOrder,
      pageData: state => state.app.pageData
    })
  },
  data() {
    const initPage = { pagesize: 10, currentPage: 1 };
    return {
      initPage,
      page: Object.assign({}, initPage),
      //
      objStatu: {
        0: "未付款",
        1: "已付款"
      },
      aryList: [],
      intTol: 0
    };
  },
  methods: {
    vodGetPage(page) {
      this.page.currentPage = page;
      this.getRechargeOrder();
    },
    async getRechargeOrder() {
      const data = {
        page: this.page.currentPage,
        pagesize: this.page.pagesize
      };
      await this.$store.dispatch("recharge/getRechargeOrder", data);
    },
    //
    vodGetData() {
      var objS = {
        page: this.intCurPage,
        perpage: this.intPerPage
      };
      new clsAxiosReq({
        strUrl: "member_acts/depositRec/r",
        objSend: objS,
        func: obj => {
          this.aryList = obj.data.list;
          this.intTolCnt = obj.data.total_count;
          this.query_url = obj.data.query_url;
        }
      });
    }
  },
  mounted() {
    this.getRechargeOrder();
  }
};
</script>
<style>
@import "~@/assets/css/trading-details.css";
</style>
