import request from "@/plugins/request";

const baseUrl = "direct-purchase";
function checkoutDirectpurchase(data) {
  return request({
    url: baseUrl + "/checkout",
    method: "POST",
    data
  });
}
function record(data) {
  return request({
    url: baseUrl + "/record",
    method: "POST",
    data
  });
}

export const directpurchaseApi = {
  checkoutDirectpurchase,
  record
};
