import Vue from "vue";
import * as $api from "@/lib/api";

const state = {
  downloadList: [],
};

const mutations = {
  DOWNLOADLIST: (state, data) => {
    state.downloadList = data;
  },
};

const actions = {
  async getDownloadList({ commit }) {
    let data = await $api.appSettingApi.getDownloadList();
    commit("DOWNLOADLIST", data.data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
