export function getStorage(key) {
  return localStorage.getItem(key) || ''
}

export function setStorage(key, token) {
  return localStorage.setItem(key, token)
}

export function removeStorage(key) {
  return localStorage.removeItem(key)
}

export function removeAllStorage(key) {
  localStorage.clear()
}
