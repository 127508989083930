import Vue from "vue";
import * as $api from "@/lib/api";

const state = {
  audioList: [],
};

const mutations = {
  SETAUDIOLIST: (state, data) => {
    state.audioList = data;
  },
};

const actions = {
  async getAudioList({ commit }) {
    let data = await $api.audioApi.getAudioList();
    commit("SETAUDIOLIST", data.data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
