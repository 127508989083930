import Vue from "vue";
import * as $api from "@/lib/api";

const state = {
  homeAdList: [],
  welcomeImg: [],
  loginAdList: [],
  welcomeFlag: false,
  welcomeTimer: ""
};

const mutations = {
  setHomeAdList: (state, data) => {
    state.homeAdList = data;
  },
  setWelcomeImg: (state, data) => {
    state.welcomeImg = data;
    state.welcomeFlag = true;
  },
  setLoginAdList: (state, data) => {
    state.loginAdList = data;
  }
};

const actions = {
  async openImgFancybox({ commit, dispatch }, { img, link }) {
    // 登入廣告
    $.fancybox.open({
      type: "image",
      src: Vue.prototype.$baseImgUrl + img,
      opts: {
        touch: false,
        clickContent: function(c, e) {
          if (e) {
            link && (location.href = link)
            $.fancybox.close();
          }
        }
      }
    });
  },
  async getHomeAdList({ commit }, _d) {
    let data = await $api.imageApi.getHomeAdList(_d);
    let homeAdList = data.data.filter(e => e.device_type === 1);
    commit("setHomeAdList", homeAdList);
  },
  async getWelcomeImg({ commit, dispatch }, _d) {
    let data = await $api.imageApi.getWelcomeImg(_d);
    let welcomeImgList = data.data.filter(e => e.device_type === 1);
    await commit("setWelcomeImg", welcomeImgList);
    // openImgFancybox
    if (welcomeImgList.length > 0) {
      welcomeImgList.forEach(e => {
        let ImgFancyboxDta = {
          img: e.image,
          link: e.url
        };
        dispatch("openImgFancybox", ImgFancyboxDta);
      });
    }
  },
  async getLoginAdList({ commit, dispatch }, _d) {
    let data = await $api.imageApi.getLoginAdList(_d);
    let LoginAdList = data.data.filter(e => e.device_type === 1);

    commit("setLoginAdList", LoginAdList);
    // openImgFancybox
    if (LoginAdList.length > 0) {
      LoginAdList.forEach(e => {
        let ImgFancyboxDta = {
          img: e.image,
          link: e.url
        };
        dispatch("openImgFancybox", ImgFancyboxDta);
      });
    }
    //
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
