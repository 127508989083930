import request from "@/plugins/request";

const baseUrl = "coin";
function getCoinList(data) {
  return request({
    url: baseUrl + "/list",
    method: "POST",
    data
  });
}

export const coinApi = {
  getCoinList
};
