import * as $api from "@/lib/api";

const state = {
  gameList: [],
  gameUrl: ''
};

const mutations = {
  setGameList: (state, data) => {
    state.gameList = data;
  },
  setGameUrl: (state, data) => {
    state.gameUrl = data;
  }
};

const actions = {
  async getGameList({ commit }, _d) {
    let data = await $api.msgamingApi.getGameList(_d);
    commit("setGameList", data.data);
  },
  async getGameUrl({ commit }, _d) {
    let data = await $api.msgamingApi.getGameUrl(_d);
    commit("setGameUrl", data.data.game_url);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
