import * as $api from "@/lib/api";

const state = {
  stickersSettings: {},
  stickerOrderList: []
};

const mutations = {
  setStickersSettings: (state, data) => {
    state.stickersSettings = data;
  },
  setStickerOrder: (state, data) => {
    state.stickerOrderList = data;
  }
};

const actions = {
  async getStickersSettings({ commit, dispatch }, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.stickersApi.getStickersSettings(_d);
    commit("setStickersSettings", data.data[0]);
  },
  async getStickerOrder({ commit, dispatch }, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.stickersApi.getStickerOrder(_d);
    commit("setStickerOrder", data.data.list);
    await commit(
      "app/setPageData",
      {
        allnums: data.data.allnums,
        page: data.data.page,
        pagesize: data.data.pagesize
      },
      { root: true }
    );
  },
  async checkoutSticker({ commit, dispatch }, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.stickersApi.checkoutSticker(_d);
    return data.status;
  },
  async pickupSticker({ commit, dispatch }, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.stickersApi.pickupSticker(_d);
    if (data.status) {
      return data.status;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
