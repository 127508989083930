import * as $api from "@/lib/api";

const state = {
  coinList: []
};

const mutations = {
  setCoinList: (state, data) => {
    state.coinList = data;
  }
};

const actions = {
  getCoinData({ state }, _d) {
    const data = state.coinList.find(x => x.code === _d.code);
    return data[_d.key];
  },
  async getCoinList({ commit }, _d) {
    let data = await $api.coinApi.getCoinList(_d);
    commit("setCoinList", data.data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
