import * as $api from "@/lib/api";

const state = {
  specificationList: [],
  instructionList: [],
  termsOfServiceList: [],
  maintenances: [{ status: 0, url: "" }],
  copyright: {},
  seoSettings: {},
  menu: []
};

const mutations = {
  setSpecificationList: (state, data) => {
    state.specificationList = data;
  },
  setInstructionList: (state, data) => {
    state.instructionList = data;
  },
  setTermsOfServiceList: (state, data) => {
    state.termsOfServiceList = data;
  },
  setMaintenances: (state, data) => {
    state.maintenances = data;
  },
  setCopyright: (state, data) => {
    state.copyright = data;
  },
  setSeoSettings: (state, data) => {
    state.seoSettings = data[0];
  },
  setMenu: (state, data) => {
    state.menu = data;
  }
};

const actions = {
  async getSpecificationList({ commit }, _d) {
    let data = await $api.websiteApi.getSpecificationList(_d);
    commit("setSpecificationList", data.data);
  },
  async getInstructionList({ commit }, _d) {
    let data = await $api.websiteApi.getInstructionList(_d);
    commit("setInstructionList", data.data);
  },
  async getTermsOfServiceList({ commit }, _d) {
    let data = await $api.websiteApi.getTermsOfServiceList(_d);
    commit("setTermsOfServiceList", data.data[0]);
  },
  async getMaintenances({ commit }, _d) {
    let data = await $api.websiteApi.getMaintenances(_d);
    if (!data) {
      data = [{ status: 0, url: "" }];
    } else {
      data = data.data.filter(e => e.type === 1);
    }
    commit("setMaintenances", data[0]);
  },
  async getCopyright({ commit }, _d) {
    let data = await $api.websiteApi.getCopyright(_d);
    commit("setCopyright", data.data[0]);
  },
  async getSeoSettings({ commit }, _d) {
    let data = await $api.websiteApi.getSeoSettings(_d);
    commit("setSeoSettings", data.data);
  },
  async getMenu({ commit }, _d) {
    let data = await $api.websiteApi.getMenu(_d);
    commit("setMenu", data.data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
