import request from "@/plugins/request";

const baseUrl = "website";
function getSpecificationList(data) {
  return request({
    url: baseUrl + "/specifications",
    method: "POST",
    data
  });
}
function getInstructionList(data) {
  return request({
    url: baseUrl + "/instructions",
    method: "POST",
    data
  });
}
function getTermsOfServiceList(data) {
  return request({
    url: baseUrl + "/terms-of-service",
    method: "POST",
    data
  });
}
function getMaintenances(data) {
  return request({
    url: baseUrl + "/maintenances",
    method: "POST",
    data
  });
}
function getCopyright(data) {
  return request({
    url: baseUrl + "/copyright",
    method: "POST",
    data
  });
}
function getSeoSettings(data) {
  return request({
    url: baseUrl + "/seo-settings",
    method: "POST",
    data
  });
}
function getMenu(data) {
  return request({
    url: baseUrl + "/client-menu",
    method: "POST",
    data
  });
}

export const websiteApi = {
  getSpecificationList,
  getInstructionList,
  getTermsOfServiceList,
  getMaintenances,
  getCopyright,
  getSeoSettings,
  getMenu
};
