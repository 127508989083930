<template>
  <div class="nr-mem-reclot">
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <CompMenNavmem :keyy="'memberReclot'" />
        <div class="r-content" style="min-height: 600px">
          <h2>抽獎記錄/提領</h2>
          <div class="btn">
            <!-- <router-link class="btn-xn" to="/mem/getlotv"></router-link> -->
            <router-link class="btn-st" to="/mem/getlotr"></router-link>
            <router-link
              style="margin-right: 15px; background: url(assets/img/btn-dz.png)"
              to="/mem/getlott"
            ></router-link>
          </div>
          <table>
            <thead>
              <tr>
                <!--<th></th>-->
                <th>抽獎標題</th>
                <th>獎品名稱</th>
                <th>抽獎時間</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in recordList" :key="index">
                <!--<td>{{item.id}}</td>-->
                <td
                  style="text-align: center; width: 33%; vertical-align: middle"
                >
                  {{ item.name }}
                </td>
                <!--<td> {{item.strPrize}} </td>-->
                <td style="cursor: pointer" v-on:click="vodPopImg(item)">
                  ({{ item.award_level }}) {{ item.award }}
                </td>
                <td>{{ item.created_at }}</td>
              </tr>
            </tbody>
          </table>
          <CompPager
            @evtpagerchg="vodGetPage"
            :int-per-page="page.pagesize"
            :int-tol-cnt="pageData.allnums"
            :int-cur-page="page.currentPage"
            ref="pager"
          ></CompPager>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompPager: require("@/components/parts/pager").default,
    CompMenNavmem: require("@/components/parts/Navmem").default,
  },
  computed: {
    ...mapState({
      pageData: (state) => state.app.pageData,
      recordList: (state) => state.activity.recordList,
    }),
    ...mapGetters({
      loginState: "loginState",
      webMode: "webMode",
    }),
  },
  data() {
    const initPage = { pagesize: 10, currentPage: 1 };
    return {
      initPage,
      page: Object.assign({}, initPage),
    };
  },
  methods: {
    vodGetPage(page) {
      this.page.currentPage = page;
      this.getRecord();
    },
    async getRecord() {
      const data = {
        page: this.page.currentPage,
        pagesize: this.page.pagesize,
        token: sessionStorage.token,
      };
      const loading = this.$loadingCase("查詢中");
      await this.$store.dispatch("activity/record", data);
      loading.close();
    },
    vodPopImg(item) {
      $.fancybox.open({
        src: item.image,
        type: "image",
        opts: {
          caption: `${item.name} (${item.award_level}) ${item.award}`,
        },
      });
    },
  },
  async mounted() {
    await this.getRecord();
  },
};
</script>

<style scoped>
@import "~@/assets/css/member-cjjl.css";

/* @import "~/@assets/css/member-cjjl.css"; */
</style>