<template>
  <div>
    <div class="header-content" navid="sy">
      <div class="header">
        <div class="center">
          <router-link to="/home" @click.native="playSound">
            <h1><img :src="$baseImgUrl + seoSettings.logo" alt="" /></h1>
          </router-link>
          <div class="r-nav">
            <div class="zt">
              <template v-if="loginState">
                <div class="user">
                  <strong>{{ userInfo.name }}</strong> 你好
                </div>
                <template v-if="!webMode">
                  <div>
                    希望幣：<span class="cr">{{
                      userInfo.D | toThousandFilter
                    }}</span>
                  </div>
                  <div>
                    金幣：<span class="cy">{{
                      userInfo.G | toThousandFilter
                    }}</span>
                  </div>
                  <div>
                    銀幣：<span class="cg">{{
                      userInfo.S | toThousandFilter
                    }}</span>
                  </div>
                  <div>
                    銅幣：<span class="c4">{{
                      userInfo.C | toThousandFilter
                    }}</span>
                  </div>
                </template>
                <router-link to="/cart/cart">
                  <div class="gwc">{{ cartList.length }} 件</div>
                </router-link>
                <router-link :to="{ name: 'msg', params: { strCat: '4' } }">
                  <div class="gwm">
                    <span v-show="intMsgUnr > 0">
                      {{ Number(intMsgUnr) > 99 ? "99+" : intMsgUnr }}
                    </span>
                    <font color="white">聯繫客服</font>
                  </div>
                </router-link>
                <router-link to="/mem/info">
                  <div class="pd-0-5 bg-blue">會員中心</div>
                </router-link>
                <router-link to="/download">
                  <div>APP下載</div>
                </router-link>
                <a v-on:click="logout()" style="cursor: pointer;" >
                  <div>登出</div>
                </a>
              </template>
              <template v-else>
                <router-link class="border" to="/reg">會員註冊</router-link>
                <router-link class="border" to="/login">登入</router-link>
                <router-link class="border" to="/download">
                  APP下載
                </router-link>
              </template>
              <div style="cursor: pointer" v-on:click="vodSnd()">
                <img
                  v-if="objConf.bolSnd"
                  src="@/assets/img/snd_on.png"
                  class="snd on"
                  style="width: 18px"
                />
                <img
                  v-else
                  src="@/assets/img/snd_off.png"
                  class="snd off"
                  style="width: 18px"
                />
              </div>
            </div>
            <div class="nav">
              <ul class="nav-list">
                <li
                  v-for="(item, index) in menu"
                  :key="index"
                  :id="item.code"
                  v-show="
                    !webMode || item.code === 'dbuy' || item.code === 'msg'
                  "
                >
                  <router-link
                    :to="{ name: item.code }"
                    @click.native="playSound"
                    >{{ item.name }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="not">
      <div class="center">
        <marquee-text
          :duration="40"
          :paused="paused"
          @mouseover="paused = true"
          @mouseout="paused = false"
          style="width: 1134px"
        >
          <span
            style="font-size: 18px"
            v-for="(mar, key) in marqueesList"
            :key="key"
            >【重要公告】{{ mar.content }}</span
          >
        </marquee-text>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MarqueeText from "vue-marquee-text-component";
export default {
  name: "CompHeader",
  components: {
    MarqueeText,
  },
  props: {},
  computed: {
    ...mapGetters({
      loginState: "loginState",
      webMode: "webMode",
      cartList: "product/cartList",
    }),
    ...mapState({
      seoSettings: (state) => state.website.seoSettings,
      userInfo: (state) => state.member.userInfo,
      marqueesList: (state) => state.notice.marqueesList,
      messageList: (state) => state.notice.messageList,
      menu: (state) => state.website.menu,
      ActivityMsgNoReadCount: (state) => state.news.ActivityMsgNoReadCount,
      msgNoReadCount: (state) => state.notice.msgNoReadCount,
      // cartList: state => state.product.cartList
    }),
    intMsgUnr() {
      return this.ActivityMsgNoReadCount + this.msgNoReadCount;
    },
  },
  data() {
    return {
      objConf: JSON.parse(localStorage.getItem("objConf") || '{"bolSnd":true}'),
      paused: false,
    };
  },
  methods: {
    vodSnd() {
      this.objConf.bolSnd = !this.objConf.bolSnd;
      localStorage.setItem("objConf", JSON.stringify(this.objConf));
    },
    async getmarquees(lottery_id) {
      await this.$store.dispatch("notice/getmarquees");
    },
    async logout() {
      await this.$store.dispatch("member/logout");
    },
    playSound() {
      if (this.objConf.bolSnd) {
        this.$bus.$emit("play:music", "menu_change");
      }
    },
  },
  async mounted() {
    await this.getmarquees();
    window.objConf = this.objConf;
  },
};
</script>

<style scoped>
@import "~@/assets/css/global.css";
.marquee-text-text {
  /* width: 1134px; */
  /* height: 40px; */
}
.marquee-text-text span {
  line-height: 40px;
  margin-left: 20px;
}
.bg-blue {
  background-color: #329dd4;
}
.pd-0-5 {
  padding: 0 5px;
}
</style>
