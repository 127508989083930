import request from "@/plugins/request";

const baseUrl = "audio";
function getAudioList() {
  return request({
    url: baseUrl + "/list",
    method: "POST",
  });
}

export const audioApi = {
  getAudioList,
};
