<template>
  <div class="nr">
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div id="cbuydet" class="ctnr">
        <div class="l-nav">
          <router-link
            class="act"
            to="/cbuy/list"
          >
            購買項目 <i>></i>
          </router-link>
          <router-link class="" to="/cbuy/his">
            交易明細 <i>></i>
          </router-link>
        </div>
        <div class="r-content">
          <h2>選擇支付方式</h2>
          <div class="bg">
            <h3 v-if="!showOkRecharge">
              請選擇付款方式 (選擇其一)
            </h3>
            <div v-if="!showOkRecharge" class="check">
              <label v-for="(item, index) in paymentMethodList" :key="index">
                <input
                  v-model="data.payid"
                  type="radio"
                  :value="item.id"
                  name="rdoPayType"
                >
                <span>{{ item.name }}</span>
                <span>手續費：
                  <template v-if="!item.feetype">$</template>
                  {{ item.fee || 0 | toThousandFilter }}
                  <template v-if="item.feetype">%</template>
                </span>
              </label>
            </div>
            <div v-if="showOkRecharge" class="ts">
              <p>產生繳款代碼是屬一次性帳號</p>
              <p>請勿重覆匯款或繳款</p>
            </div>

            <div v-else class="tips" v-html="rechargeNoticeList.content" />
            <a
              v-if="!showOkRecharge"
              @click="checkoutRecharge()"
            ><input
              type="submit"
              class="sbm"
              value="確認送出"
            ></a>
            <a v-else target="_blank" @click="connectRechargeLink()">
              <input type="button" class="sbm" value="確認送出連線付款">
            </a>
          </div>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getStorage, setStorage } from '@/plugins/auth'
export default {
  components: {
    CompMarquee: require('@/components/parts/Marquee').default,
    CompFoot: require('@/components/parts/Foot').default
  },
  computed: {
    ...mapState({
      _paymentMethodList: state => state.other.paymentMethodList,
      rechargeNoticeList: state => state.precautions.rechargeNoticeList,
      okRechargeData: state => state.recharge.okRechargeData
    })
  },
  data () {
    return {
      showOkRecharge: false,
      paymentMethodList: [],
      data: {
        recharegeid: 0,
        payid: '',
        vehicle: ''
      }
    }
  },
  mounted () {
    if (this.$route.params.id && this.$route.params.paytype) {
      this.paytype = this.$route.params.paytype
        .split(',')
        .map(x => Number(x))
      this.data.recharegeid = Number(this.$route.params.id)
      this.data.vehicle = this.$route.params.vehicle
    }
    this.getPaymentMethodList()
    this.getRechargeNoticeList()
  },
  methods: {
    async getPaymentMethodList () {
      await this.$store.dispatch('other/getPaymentMethodList')
      this.paymentMethodList = this._paymentMethodList.filter(x =>
        this.paytype.includes(x.id)
      )
      this.data.payid = this.paymentMethodList[0].id
    },
    async getRechargeNoticeList () {
      await this.$store.dispatch('precautions/getRechargeNoticeList')
    },
    async checkoutRecharge () {
      const state = await this.$store.dispatch(
        'recharge/checkoutRecharge',
        this.data
      )
      if (state) {
        this.showOkRecharge = state
        this.$snotify.info('繳費連結已建立')
      }
    },
    connectRechargeLink () {
      this.$store.dispatch('recharge/connectRechargeLink', this.okRechargeData)
      sessionStorage.setItem('wait', true)
    }
  }
}
</script>
<style scoped>
@import "~@/assets/css/buy-items2.css";
</style>
