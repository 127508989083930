import Vue from "vue";
import * as $api from "@/lib/api";

const state = {
  lottList: [],
  detail: {},
  lottSingleAction: { award: [{ img: "" }] },
  lottMultiAction: { award: [] },
  hotlist: {},
  recordList: []
};

const getters = {};

const mutations = {
  GETLOTTLIST: (state, data) => {
    state.lottList = data;
  },
  SETDETAIL: (state, data) => {
    state.detail = data;
  },
  ACTION: (state, data) => {
    state.lottSingleAction = data;
  },
  ACTIONMULTITIMES: (state, data) => {
    state.lottMultiAction.award.push(data);
  },
  CLEANLOTTMULTIACTION: state => {
    state.lottMultiAction = { award: [] };
  },
  HOTLIST: (state, data) => {
    state.hotlist = data;
  },
  RECORD: (state, data) => {
    state.recordList = [];
    state.recordList = data;
  }
};

const actions = {
  async getLottList({ commit }, _d) {
    let data = await $api.activityApi.getLottList(_d);
    commit("GETLOTTLIST", data.data.list);

    await commit(
      "app/setPageData",
      {
        allnums: data.data.allnums,
        page: data.data.page,
        pagesize: data.data.pagesize
      },
      { root: true }
    );
  },
  async getDetail({ commit }, _d) {
    let data = await $api.activityApi.getDetail(_d);
    commit("SETDETAIL", data.data);
    return data.status;
  },
  async action({ commit }, _d) {
    let data = await $api.activityApi.action(_d);
    data && commit("ACTION", data.data);
    return data;
  },
  async actionMultiTimes({ commit }, _d) {
    let data = await $api.activityApi.action(_d);
    // let scrollHeight = $('#lott-ul')[0].scrollHeight
    // if (data) {
    //   console.log('data.data.award.length',data.data.award.length)
    //   data.data.award.forEach((e, i) => {
    //     setTimeout(() => {
    //       commit("ACTIONMULTITIMES", e);
    //       if($('#lott-ul')[0].scrollHeight > scrollHeight){
    //         scrollHeight = $('#lott-ul')[0].scrollHeight
    //         $('#lott-ul')[0].scrollTop = scrollHeight
    //       }   
    //       if(i === data.data.award.length - 1){
    //         console.log('end')
    //       }
   
    //     }, i * 200);
    //   });
    // }
    return data
  },
  async hotlist({ commit }, _d) {
    let data = await $api.activityApi.hotlist(_d);
    commit("HOTLIST", data.data);
  },
  async record({ commit }, _d) {
    let data = await $api.activityApi.record(_d);
    commit("RECORD", data.data.list);

    await commit(
      "app/setPageData",
      {
        allnums: data.data.allnums,
        page: data.data.page,
        pagesize: data.data.pagesize
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
