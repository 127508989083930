<template>
  <div class="nr-dbuy">
    <div class="center">
      <div class="tit">
        <h2>{{ this.menu | transMenuName(this.menuType) }}</h2>
      </div>
      <CompMarquee />
      <div class="list">
        <div class="fx">
          <div class="title-list">
            <router-link
              :to="{ name: 'dbuyList', params: { id: 0 } }"
              @click="mainTypeId = ''"
              :class="{ fst: true, on: mainTypeId == '' }"
              >全部</router-link
            >
            <router-link
              :to="{ name: 'dbuyList', params: { id: item.id } }"
              v-for="(item, index) in typeList"
              :key="index"
              :class="{
                brn: index == typeList.length - 1,
                on: item.id == mainTypeId,
              }"
              >{{ item.name }}</router-link
            >
            <!-- <a href="" @click="mainTypeId = 0" :class="{ on: mainTypeId == '0' }">其他</a> -->
          </div>
        </div>
        <div class="search">
          <div style="font-size: 18px; font-weight: bold">
            次分類:
            <select
              v-model="subtypeId"
              style="font-size: 16px; font-weight: bold; color: black"
            >
              <option value="" style="color: black">全部</option>
              <option
                v-for="(item, index) in subtypeList"
                :key="index"
                :value="item.id"
                style="color: black"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div>
            <input
              type="text"
              placeholder="搜尋產品"
              v-model.trim="searchName"
            />
            <span class="btn btn-warning" @click="search()">搜尋</span>
          </div>
        </div>
        <CompProductItem />
        <CompPager
          @evtpagerchg="vodGetPage"
          :int-per-page="page.pagesize"
          :int-tol-cnt="pageData.allnums"
          :int-cur-page="page.currentPage"
          ref="pager"
        ></CompPager>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompPager: require("@/components/parts/pager").default,
    CompProductItem: require("@/components/productItem").default,
  },
  computed: {
    ...mapState({
      typeList: (state) => state.product.typeList,
      productList: (state) => state.product.productList,
      pageData: (state) => state.app.pageData,
      subtypeList: (state) => state.product.subtypeList,
      menu: (state) => state.website.menu,
    })
  },
  data() {
    const initPage = { pagesize: 15, currentPage: 1 };
    return {
      initPage,
      page: Object.assign({}, initPage),
      mainTypeId: "",
      searchName: "",
      subtypeId: "",
      menuType:'dbuy'
    };
  },
  watch: {
    mainTypeId: async function (id) {
      this.page = { pagesize: 15, currentPage: 1 };
      this.subtypeId = "";
      this.searchName = "";
      this.getProduct(id);
      this.getsubtype(id);
    },
    subtypeId: async function (id) {
      this.page = { pagesize: 15, currentPage: 1 };
      this.searchName = "";
      this.getProduct(this.mainTypeId);
    },
  },
  methods: {
    vodGetPage(page) {
      this.page.currentPage = page;
      this.getProduct(this.mainTypeId, this.searchName);
    },
    search() {
      if (this.searchName == "") {
        this.$snotify.error("請輸入搜尋關鍵字");
        return;
      }
      this.page = { pagesize: 15, currentPage: 1 };
      this.subtypeId = "";
      this.getProduct(this.mainTypeId, this.searchName);
    },
    async getProduct(id, name) {
      const data = {
        page: this.page.currentPage,
        pagesize: this.page.pagesize,
        maintype: id,
        subtype: this.subtypeId || undefined,
        name,
      };
      await this.$store.dispatch("product/getProduct", data);
    },
    async getType() {
      await this.$store.dispatch("product/getType");
      this.initScrollbar()
    },
    async getsubtype(maintype) {
      const data = {
        maintype,
      };
      await this.$store.dispatch("product/getsubtype", data);
    },
    initScrollbar() {
      this.$nextTick(()=>{
          $('.fx .title-list').mCustomScrollbar({
              axis:"x",
              scrollbarPosition: "outside",
          })
      })
    },
  },
  async mounted() {
    await this.getType();
    await this.getProduct();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.id) {
      this.mainTypeId = Number(to.params.id);
    } else {
      this.mainTypeId = "";
    }
  },
};
</script>
<style scoped>
@import "~@/assets/css/direct-purchasing.css";
</style>
