<template>
  <div>
    <div class="tit">
      <strong>確認收件人</strong>
    </div>
    <div class="info-list">
      <div class="blk">
        <span>*收件姓名</span>
        <input type="text" v-model="data.receive_name" value="" placeholder="" />
      </div>
      <div class="blk">
        <span>電子信箱</span>
        <input type="text" v-model="data.receive_email" value="" placeholder="" />
      </div>
      <div class="blk">
        <span>*連絡電話</span>
        <input type="text" v-model="data.receive_phone" value="" placeholder="" />
      </div>
      <div class="blk">
        <span>*通訊地址</span>
        <input type="text" v-model="data.receive_address" value="" placeholder="" />
      </div>
      <div class="blk" v-show=" routeName === 'cartConfirm'">
        <span>發票載具</span>
        <input type="text" v-model="data.vehicle" value="" placeholder="" />
      </div>
      <div class="blk">
        <span>收件時間</span>
        <label>
          <input type="radio" value="早上08~12" v-model="data.receive_time" />
          早上08~12
        </label>
        <label>
          <input type="radio" value="下午12~17" v-model="data.receive_time" />
          下午12~17
        </label>
        <label>
          <input type="radio" value="不指定時間" v-model="data.receive_time" />
          不指定時間
        </label>
      </div>
      <div class="blk">
        <span>備註事項</span>
        <textarea v-model="data.remarks" maxlength="100"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ReceiveInfoList",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
    routeName:this.$route.name
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.member.userInfo
    })
  },
  methods: {
    async getuser() {
      await this.$store.dispatch("member/getuser");
      let _data = Object.assign({}, this.userInfo);
      this.data.receive_name = _data.name;
      this.data.receive_phone = _data.username;
      this.data.receive_email = _data.email;
      this.data.receive_address = _data.address;
    }
  },
  mounted() {
    this.getuser();
  },
  destroyed() {}
};
</script>

<style scoped>
@import "~@/assets/css/buy-car-2.css";
</style>
