<template>
  <div class="nr-cbuy">
    <img
      v-if="checkoutNotice.url"
      :src="$baseImgUrl + checkoutNotice.url"
      :alt="checkoutNotice.content"
      width="100%"
    />
    <div class="center">
      <div class="tit">
        <h2>結帳優惠</h2>
      </div>
      <CompMarquee />
      <div class="ctnr" style="min-height: 700px">
        <div class="l-nav">
          <router-link class="act" to="/cbuy/list"
            >購買項目 <i>></i></router-link
          >
          <router-link class="" to="/cbuy/his">交易明細 <i>></i></router-link>
        </div>
        <div class="r-content">
          <h2>選擇方案</h2>
          <div class="blk">
            <span>發票載具</span>
            <input type="text" v-model="vehicle" value="" placeholder="" />
          </div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>購買標題</th>
                <th>金額</th>
                <th>購買點數</th>
                <th>贈送項目</th>
                <th>購買</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in rechargeMethodsList" :key="index">
                <td>{{ item.sort }}</td>
                <td>{{ item.name }}</td>
                <td>${{ item.money | cleanDotFilter }}</td>
                <td>{{ item.correct_quantity | cleanDotFilter }}</td>
                <td>
                  <span v-if="item.gift_quantity != 0"
                    >贈送:{{ item.gift_currency | coinName }}
                    {{ item.gift_quantity | cleanDotFilter }} 點</span
                  >
                  <span v-if="item.gift_quantity == 0">無</span>
                </td>
                <td>
                  <router-link
                    class="buy"
                    :to="{
                      name: 'cbuydet',
                      params: {
                        id: item.id,
                        paytype: item.paytype,
                        vehicle: vehicle,
                      },
                    }"
                  ></router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
  },
  computed: {
    ...mapState({
      rechargeMethodsList: (state) => state.recharge.rechargeMethodsList,
      checkoutNotice: (state) => state.precautions.checkoutNotice,
    }),
  },
  data() {
    return {
      vehicle: "",
    };
  },
  methods: {
    async getRechargeMethods() {
      await this.$store.dispatch("recharge/getRechargeMethods");
    },
    async getCheckoutNoticeList() {
      await this.$store.dispatch("precautions/getCheckoutNoticeList");
    },
  },
  mounted() {
    this.getRechargeMethods();
    this.getCheckoutNoticeList();
  },
};
</script>
<style>
@import "~@/assets/css/buy-items.css";
.blk {
  width: 100%;
  overflow: hidden;
  margin-top: 22px;
}
.blk span {
  display: block;
  width: 110px;
  text-align: left;
  height: 40px;
  line-height: 40px;
  float: left;
  font-size: 17px;
  padding-left: 5px;
}
.blk input[type="text"] {
  width: 760px;
  height: 40px;
  float: left;
  display: block;
  background: #545253;
  border: none;
  padding-left: 10px;
  color: #deddde;
  font-size: 17px;
}
</style>
