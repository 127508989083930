import request from "@/plugins/request";

const baseUrl = "image";
function getHomeAdList(data) {
  return request({
    url: baseUrl + "/home-ads",
    method: "POST",
    data
  });
}
function getWelcomeImg(data) {
  return request({
    url: baseUrl + "/welcome",
    method: "POST",
    data
  });
}
function getLoginAdList(data) {
  return request({
    url: baseUrl + "/login-ads",
    method: "POST",
    data
  });
}

export const imageApi = {
  getHomeAdList,
  getWelcomeImg,
  getLoginAdList
};
