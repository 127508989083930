<template>
  <div class="Copyright">
    {{ copyright.bottom_declare}}
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "compFoot",
  computed: {
    ...mapState({
      copyright: state => state.website.copyright
    })
  },
  methods: {
    async getCopyright(lottery_id) {
      await this.$store.dispatch("website/getCopyright");
    },
  },
  async mounted() {
    await this.getCopyright();
  }
};
</script>
