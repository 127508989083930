<template>
  <div class="nr-mem-recbuydri">
    <div
      style="display: none; padding: 0px"
      id="hidden-content2"
      data-padding="0"
    >
      <div class="nr-mem-recbuydri" style="width: 800px; background: unset">
        <div class="center" style="width: unset">
          <div class="ctnr" style="padding: unset">
            <div
              class="r-content"
              style="width: unset; float: unset; margin: unset"
            >
              <table>
                <thead>
                  <tr>
                    <th width="33%">商品名稱</th>

                    <th width="33%">數量</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in aryDet" :key="index">
                    <td>
                      <img
                        :src="item.img"
                        alt=""
                        style="height: 50px; float: left"
                      />
                      <span style="float: left">{{ item.name }}</span>
                    </td>

                    <td>
                      {{ item.qty }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <CompMenNavmem :keyy="$route.name" />
        <div class="r-content">
          <h2>領獎記錄/訂單</h2>
          <table>
            <thead>
              <tr>
                <th style="width: 100px">訂單編號</th>
                <th>訂單日期</th>

                <th>出貨狀況</th>
                <th>出貨日期</th>
                <th>快遞/編號</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in swardOrderList"
                :key="index"
                v-on:click="vodDetail(item)"
                style="cursor: pointer"
              >
                <td style="text-align: center">{{ item.single_number }}</td>

                <td>{{ item.created_at }}</td>
                <td>
                  {{ item.goods_status }}
                </td>
                <td>{{ item.shipment_at }}</td>
                <td style="line-height: 30px">
                  {{ item.logistics_name }}
                  <span v-if="item.logistics_number">/</span>
                  {{ item.logistics_number }}
                </td>
              </tr>
            </tbody>
          </table>
          <CompPager
            v-if="pageData"
            @evtpagerchg="vodGetPage"
            :int-per-page="page.pagesize"
            :int-tol-cnt="pageData.allnums"
            :int-cur-page="page.currentPage"
            ref="pager"
          ></CompPager>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompPager: require("@/components/parts/pager").default,
    CompMenNavmem: require("@/components/parts/Navmem").default,
  },
  computed: {
    ...mapState({
      swardOrderList: (state) => state.receiveaward.swardOrderList,
      pageData: (state) => state.app.pageData,
    }),
  },
  data() {
    const initPage = { pagesize: 10, currentPage: 1 };
    return {
      initPage,
      page: Object.assign({}, initPage),
      //
      aryDet: [],
    };
  },
  methods: {
    vodGetPage(page) {
      this.page.currentPage = page;
      this.getAwardOrder();
    },
    async getAwardOrder() {
      const data = {
        page: this.page.currentPage,
        pagesize: this.page.pagesize,
      };
      await this.$store.dispatch("receiveaward/getAwardOrder", data);
    },
    //
    vodDetail(obj) {
      $.fancybox.open({
        src: obj.img,
        type: "image",
        opts: {
          caption: obj.name + '-' + obj.qty,
        },
      });
    },
  },

  async mounted() {
    await this.getAwardOrder();
  },
};
</script>
<style scoped>
@import "~@/assets/css/member-spjl.css";
</style>
