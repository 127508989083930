import * as $api from "@/lib/api";

const state = {
  marqueesList: [],
  winmarqueesList: [],
  messageList: [],
  msgNoReadCount: 0
};

const mutations = {
  setMarquees: (state, data) => {
    state.marqueesList = data;
  },
  setWinmarqueesList: (state, data) => {
    state.winmarqueesList = data;
  },
  SETMESSAGELIST: (state, data) => {
    state.messageList = data;
    state.msgNoReadCount = data.filter(e => e.mstatus === "未讀" && e.status ==='已回信').length
  }
};

const actions = {
  async getmarquees({ commit }, _d) {
    let data = await $api.noticeApi.getmarquees(_d);
    commit("setMarquees", data.data);
  },
  async getWinmarquees({ commit }, _d) {
    let data = await $api.noticeApi.getWinmarquees(_d);
    commit("setWinmarqueesList", data.data);
  },
  async getMessageList({ commit, dispatch }, { _d, routeName }) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.noticeApi.getMessageList(_d);
    commit("SETMESSAGELIST", data.data.list);
    if (routeName === "msg") {
      await commit(
        "app/setPageData",
        {
          allnums: data.data.allnums,
          page: data.data.page,
          pagesize: data.data.pagesize
        },
        { root: true }
      );
    }
    return data;
  },
  async readMessage({ commit }, _d) {
    let data = await $api.noticeApi.readMessage(_d);
  },
  async service({ commit }, _d) {
    let data = await $api.noticeApi.service(_d);
    return data.status;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
