<template>
  <div class="nr-mem-take">
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <CompMenNavmem :keyy="'memberReclot'" />
        <div class="r-content">
          <el-row type="flex" justify="space-between" class="top-content">
            <h2>領取電子禮券(提領後將以站內訊息發送)</h2>
            <div class="btn-ct">
              <input
                type="submit"
                value="確認領取"
                v-on:click="vodSend()"
                id="iptBtnSend"
              />
            </div>
          </el-row>

          <div class="tab">
            <table>
              <thead>
                <tr>
                  <th><input type="checkbox" @change="checkedAll" /></th>
                  <th class="th2">獎項</th>
                  <th class="th3">獎品內容</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in recordList" :key="index">
                  <td>
                    <input :value="item.id" class="iptChk" type="checkbox" />
                  </td>
                  <td class="th2">{{ item.name }}</td>
                  <td class="th3">({{ item.award_level }}) {{ item.award }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="btn-ct">
          <input
            type="submit"
            value="完成確認"
            v-on:click="vodSend()"
            id="iptBtnSend"
          />
        </div> -->
      </div>

      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompMenNavmem: require("@/components/parts/Navmem").default,
    ReceiveInfoList: require("@/components/ReceiveInfoList").default,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.member.userInfo,
      recordList: (state) => state.activity.recordList,
    }),
  },
  data() {
    return {
      sendDataObj: "",
    };
  },
  methods: {
    vodSend() {
      var ary = Array.from(document.getElementsByClassName("iptChk")).filter(
        (obj) => {
          if (obj.checked == true) {
            return true;
          }
        }
      );
      if (ary.length == 0) {
        this.$root.$snotify.error("請選擇欲提領獎品");
        return;
      }
      var idStr = ary
        .map((obj) => {
          return obj.value;
        })
        .join(",");
      this.send(JSON.stringify({ id: idStr }), ary);

      // this.send();
    },
    async send(id, checkAry) {
      const loading = this.$loadingCase("領取中")
      const state = await this.$store.dispatch("receiveaward/eVoucher", {
        winId: id,
      });
      if (state) {
        this.$snotify.info("禮券序號領取成功,請查看您的站內信");
        await this.getRecord();
        checkAry.forEach((e) => (e.checked = false));
      }
      loading.close();
    },
    checkedAll(event) {
      var input = document.querySelectorAll(".iptChk");
      var checked = Array.from(input).filter((item) => item.checked);

      input.forEach((item) => {
        item.checked = event.target.checked;
      });
    },
    async getRecord() {
      const data = {
        page: 1,
        pagesize: 1000,
        token: sessionStorage.token,
        type: "1",
        status: "0",
      };
      const loading = this.$loadingCase("查詢中")
      await this.$store.dispatch("activity/record", data);
      loading.close();
    },
  },
  async mounted() {
    await this.getRecord();
  },
};
</script>
<style scoped >
@import "~@/assets/css/member-lq.css";
.top-content {
  border-bottom: 2px solid #882f80;
  padding: 10px 20px 10px 10px;
}
.top-content h2 {
  border-bottom: none !important;
}
.top-content .btn-ct {
  margin-top: 0;
  width: 230px;
}
.top-content .btn-ct input[type="submit"] {
}
</style>
