import * as $api from '@/lib/api'
import $router from '@/router'
import { setStorage } from '@/plugins/auth'

const state = {
  rechargeMethodsList: [],
  rechargeOrder: [],
  okRechargeData: false,
  isShowPaymentCb: false
}

const mutations = {
  setRechargeMethods: (state, data) => {
    state.rechargeMethodsList = data
  },
  setRechargeOrder: (state, data) => {
    state.rechargeOrder = data
  },
  setOkRechargeData: (state, data) => {
    state.okRechargeData = data
  },
  setPaymentCbVisible: (state, boo) => {
    state.isShowPaymentCb = boo || !state.isShowPaymentCb
  }
}

const actions = {
  async fetchPaymentResult ({ commit, dispatch }) {
    const _d = await dispatch('app/addToken', {}, { root: true })
    const res = await $api.rechargeApi.showSuccessInfo(_d)
    if (res.status && res.msg) {
      commit('setPaymentCbVisible', true)
      sessionStorage.removeItem('wait')
    }
  },
  async getRechargeMethods ({ commit, dispatch }, _d) {
    _d = await dispatch('app/addToken', _d, { root: true })
    const data = await $api.rechargeApi.getRechargeMethods(_d)
    commit('setRechargeMethods', data.data)
  },
  async getRechargeOrder ({ commit, dispatch }, _d) {
    _d = await dispatch('app/addToken', _d, { root: true })
    const data = await $api.rechargeApi.getRechargeOrder(_d)
    await commit(
      'app/setPageData',
      {
        allnums: data.data.allnums,
        page: data.data.page,
        pagesize: data.data.pagesize
      },
      { root: true }
    )
    commit('setRechargeOrder', data.data.list)
  },
  async checkoutRecharge ({ commit, dispatch }, _d) {
    _d = await dispatch('app/addToken', _d, { root: true })
    const data = await $api.rechargeApi.checkoutRecharge(_d)
    commit('setOkRechargeData', data.data)
    if (data.status) {
      return data.status
    }
  },
  connectRechargeLink ({ commit, dispatch }, payData) {
    if (payData.outtpye === 'output') {
      setStorage('payment', JSON.stringify(payData))
      const { href } = $router.resolve({
        name: 'payment'
      })
      window.open(href, '_blank', 'toolbar=yes, width=1300, height=900')
    }
    if (payData.outtpye === 'outurl') {
      window.open(payData.outurl.web, '_blank', 'toolbar=yes, width=1300, height=900')
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
