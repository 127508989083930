<template>
  <ul v-if="coinList.length > 0">
    <li
      v-for="(item, index) in productList"
      :key="index"
      :title="item.name_prd"
    >
      <router-link :to="{ name: 'dbuydet', params: { id: item.id } }">
        <div class="img-goods">
          <img :src="item.img" alt="" />
        </div>
        <h4>{{ item.name }}</h4>
        <div class="cz">
          <template v-if="!webMode">
            <div class="top">
              福利價 -1
              <span class="red" style="font-size: 1rem">
                {{ item.welfare_price1[0].coin | coinName }}
                {{ item.welfare_price1[0].value | toThousandFilter }}
              </span>
              <i>+</i>
              <span class="green" style="font-size: 1rem">
                {{ item.welfare_price1[1].coin | coinName }}
                {{ item.welfare_price1[1].value | toThousandFilter }}
              </span>
            </div>
            <div class="top">
              福利價 -2
              <span class="red" style="font-size: 1rem">
                {{ item.welfare_price2[0].coin | coinName }}
                {{ item.welfare_price2[0].value | toThousandFilter }}
              </span>
              <i>+</i>
              <span class="green" style="font-size: 1rem">
                {{ item.welfare_price2[1].coin | coinName }}
                {{ item.welfare_price2[1].value | toThousandFilter }}
              </span>
            </div>
          </template>
          <div class="btm" v-else>
            <div>
              直購價
              <strong style="width: 140px"
                >${{ item.direct_price | toThousandFilter }}</strong
              >
            </div>
            <!-- <div>
              <span>
                贈
                {{ item.gift_price.coin | coinName }}
                :
                {{ item.gift_price.value | toThousandFilter }}
              </span>
            </div> -->
          </div>
          <!-- <button class="jr" @click="vodAddCart($event,item)">
            <img src="@/assets/img/jr-icon.png" alt="" />放入購物車
          </button> -->
        </div>
      </router-link>
      <div class="jr-wrapper">
        <button class="jr" @click="vodAddCart(item)">
          <img src="@/assets/img/jr-icon.png" alt="" />放入購物車
        </button>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "CompProductItem",
  computed: {
    ...mapGetters({
      webMode: "webMode",
      loginState: "loginState",
    }),
    ...mapState({
      productList: (state) => state.product.productList,
      coinList: (state) => state.coin.coinList,
    }),
  },
  data() {
    return {
      data: {
        price_type: 2, //(0:直購價、1:福利價1、2:福利價2)
        qty: 1,
      },
    };
  },
  methods: {
    vodAddCart(detail) {
      if (!this.loginState) {
        this.$snotify.error("未登入會員不能購買商品");
        return;
      }
      this.data.qty = parseInt(this.data.qty);
      if (!this.data.qty > 0) {
        return;
      }
      let welfareData = {
        coin1: detail.welfare_price2[0].coin,
        percent1: detail.welfare_price2[0].percent,
        value1: detail.welfare_price2[0].value,
        coin2: detail.welfare_price2[1].coin,
        percent2: detail.welfare_price2[1].percent,
        value2: detail.welfare_price2[1].value,
        pid:detail.pid,
        direct_id:detail.id
      };
      const item = Object.assign(this.data, welfareData);
      this.$store.dispatch("product/addCartList", {
        item,
        detail: Object.assign({}, detail),
      });
    },
  },
};
</script>
<style >
.nr-dbuy .center .list ul li .cz {
  height: 89px !important;
}
.jr-wrapper {
  width: 100%;
  overflow: hidden;
  padding: 0 8px;
  background: #f1eef3;
}
.jr {
  width: 100%;
  height: 36px;
  display: block;
  text-align: center;
  line-height: 36px;
  font-size: 19px;
  background: #7a00a7;
}
.jr img {
  display: inline-block;
  position: relative;
  left: -5px;
  top: 4px;
}
</style>