import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home/:parent?",
    name: "home",
    component: () => import("@/views/Home")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login")
  },
  {
    path: "/reg/:parent?",
    name: "reg",
    component: () => import("@/views/reg")
  },
  {
    path: "/forget/:parent?",
    name: "forget",
    component: () => import("@/views/forget")
  },
  {
    path: "/mem",
    component: () => import("@/views/member/index"),
    redirect: "/mem/info",
    name: "member",
    children: [
      {
        path: "info",
        name: "memberInfo",
        component: () => import("@/views/member/Info")
      },
      {
        path: "auth",
        name: "memberAuth",
        component: () => import("@/views/member/auth")
      },
      // {
      //   path: "recbuydri",
      //   name: "memberRecbuydri",
      //   component: () => import("@/views/member/recbuydri")
      // },
      {
        path: "recbuysti",
        name: "memberRecbuysti",
        component: () => import("@/views/member/recbuysti")
      },
      {
        path: "recpritake",
        name: "memberRecpritake",
        component: () => import("@/views/member/recpritake")
      },
      {
        path: "reclot",
        name: "reclot",
        component: () => import("@/views/member/reclot")
      },
      {
        path: "recbuydri",
        name: "recbuydri",
        component: () => import("@/views/member/recbuydri")
      },
      //take
      {
        path: "getsti",
        name: "getsti",
        component: () => import("@/views/member/take/getsti")
      },
      {
        path: "getlotr",
        name: "getlotr",
        component: () => import("@/views/member/take/getlotr")
      },
      {
        path: "getlotv",
        name: "getlotv",
        component: () => import("@/views/member/take/getlotv")
      },
      {
        path: "getlott",
        name: "getlott",
        component: () => import("@/views/member/take/getlott")
      }
    ]
  },
  {
    path: "/cbuy",
    component: () => import("@/views/cbuy/index"),
    redirect: "/cbuy/list",
    name: "cbuy",
    children: [
      {
        path: "list",
        name: "cbuyList",
        component: () => import("@/views/cbuy/cbuylist")
      },
      {
        path: "his",
        name: "cbuyHis",
        component: () => import("@/views/cbuy/cbuyhis")
      },
      {
        path: "cbuydet/:id?",
        name: "cbuydet",
        component: () => import("@/views/cbuy/cbuydet")
      }
    ]
  },
  {
    path: "/dbuy",
    component: () => import("@/views/dbuy/index"),
    redirect: "/dbuy/list",
    name: "dbuy",
    children: [
      {
        path: "list/:id?",
        name: "dbuyList",
        component: () => import("@/views/dbuy/dbuy"),
        meta: {
          keepAlive: true // 需要被缓存
        }
      },
      {
        path: "dbuydet/:id",
        name: "dbuydet",
        component: () => import("@/views/dbuy/dbuydet")
      }
    ]
  },
  {
    path: "/lott",
    component: () => import("@/views/lott/index"),
    redirect: "/lott/list",
    name: "lott",
    children: [
      {
        path: "list/:id?",
        name: "lottList",
        component: () => import("@/views/lott/lott"),
        meta: {
          keepAlive: true // 需要被缓存
        }
      },
      {
        path: "/draw/:id",
        name: "draw",
        component: () => import("@/views/lott/draw")
      }
    ]
  },
  // {
  //   path: "/draw/:id",
  //   name: "draw",
  //   component: () => import("@/views/lott/draw")
  // },
  {
    path: "/cart",
    component: () => import("@/views/cart/index"),
    redirect: "/cart/cart",
    name: "cart",
    children: [
      {
        path: "cart",
        name: "cart",
        component: () => import("@/views/cart/cart")
      },
      {
        path: "cartConfirm/:strBType/:showPaymentList",
        name: "cartConfirm",
        component: () => import("@/views/cart/cart_confirm")
      },
      {
        path: "cartFinal/:strBType/:showPaymentList",
        name: "cartFinal",
        component: () => import("@/views/cart/cart_final_test")
        // component: () => import("@/views/cart/cart_final")
      }
    ]
  },
  {
    path: "/games",
    component: () => import("@/views/games/index"),
    redirect: "/games",
    name: "games",
    children: [
      {
        path: "/",
        name: "gamesList",
        component: () => import("@/views/games/list")
      }
    ]
  },
  //
  // {
  //   path: "/draw/:id",
  //   name: "draw",
  //   component: () => import("@/views/draw")
  // },
  {
    path: "/msg/:strCat?",
    name: "msg",
    component: () => import("@/views/msg")
  },
  {
    path: "/download",
    name: "download",
    component: () => import("@/views/download")
  },
  {
    path: "/gtrans",
    name: "gtrans",
    component: () => import("@/views/gtrans")
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("@/views/payment")
  },
  {
    path: "*",
    redirect: "/home"
  }
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
});

export default router;
