<template>
  <div class="nr-mem-take">
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <CompMenNavmem :keyy="'memberReclot'" />
        <div class="r-content">
          <h2>領取實體獎品</h2>
          <div class="tab">
            <table>
              <thead>
                <tr>
                  <th><input type="checkbox" @change="checkedAll" /></th>
                  <th class="th2">獎項</th>
                  <th class="th3">獎品內容</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in recordList" :key="index">
                  <td>
                    <input :value="item.id" class="iptChk" type="checkbox" />
                  </td>
                  <td class="th2">{{ item.name }}</td>
                  <td class="th3">({{ item.award_level }}) {{ item.award }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <ReceiveInfoList :data="data" />
          <div class="btn-ct">
            <input
              type="submit"
              value="確認領取"
              v-on:click="vodSend()"
              id="iptBtnSend"
            />
          </div>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompMenNavmem: require("@/components/parts/Navmem").default,
    ReceiveInfoList: require("@/components/ReceiveInfoList").default,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.member.userInfo,
      recordList: (state) => state.activity.recordList,
    }),
  },
  data() {
    let initData = {
      receive_name: "",
      receive_phone: "",
      receive_email: "",
      receive_address: "",
      receive_time: "不指定時間",
      remarks: "",
    };
    return {
      initData,
      data: Object.assign({}, initData),
    };
  },
  methods: {
    vodSend() {
      let objChk = {
        receive_name: { strMsg: "姓名未填" },
        receive_phone: { strMsg: "電話未填" },
        receive_address: { strMsg: "地址未填" },
      };
      for (let k in this.data) {
        if (objChk[k]) {
          if (this.data[k] == "") {
            this.$snotify.error(objChk[k]["strMsg"]);
            return;
          }
        }
      }
      var ary = Array.from(document.getElementsByClassName("iptChk")).filter(
        (obj) => {
          if (obj.checked == true) {
            return true;
          }
        }
      );
      if (ary.length == 0) {
        this.$root.$snotify.error("請選擇欲提領獎品");
        return;
      }
      var idStr = ary
        .map((obj) => {
          return obj.value;
        })
        .join(",");
      const obj = Object.assign(
        { winId: JSON.stringify({ id: idStr }) },
        this.data
      );
      this.send(obj, ary);
    },
    async send(obj, checkAry) {
      const loading = this.$loadingCase("領取中")
      const state = await this.$store.dispatch(
        "receiveaward/pickupPhysicalPrizes",
        obj
      );
      if (state) {
        this.$snotify.info("訂單送出成功");
        await this.getRecord();
        checkAry.forEach((e) => (e.checked = false));
      }
      loading.close();
    },
    checkedAll(event) {
      var input = document.querySelectorAll(".iptChk");
      var checked = Array.from(input).filter((item) => item.checked);

      input.forEach((item) => {
        item.checked = event.target.checked;
      });
    },
    async getRecord() {
      const data = {
        page: 1,
        pagesize: 1000,
        token: sessionStorage.token,
        type: "2",
        status: "0",
      };
      const loading = this.$loadingCase("查詢中")
      await this.$store.dispatch("activity/record", data);
      loading.close();
    },
  },
  async mounted() {
    await this.getRecord();
  },
};
</script>
<style scoped>
@import "~@/assets/css/member-lq.css";
</style>
