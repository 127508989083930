var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nr-cbuy"},[(_vm.checkoutNotice.url)?_c('img',{attrs:{"src":_vm.$baseImgUrl + _vm.checkoutNotice.url,"alt":_vm.checkoutNotice.content,"width":"100%"}}):_vm._e(),_c('div',{staticClass:"center"},[_vm._m(0),_c('CompMarquee'),_c('div',{staticClass:"ctnr",staticStyle:{"min-height":"700px"}},[_c('div',{staticClass:"l-nav"},[_c('router-link',{staticClass:"act",attrs:{"to":"/cbuy/list"}},[_vm._v("購買項目 "),_c('i',[_vm._v(">")])]),_c('router-link',{attrs:{"to":"/cbuy/his"}},[_vm._v("交易明細 "),_c('i',[_vm._v(">")])])],1),_c('div',{staticClass:"r-content"},[_c('h2',[_vm._v("選擇方案")]),_c('div',{staticClass:"blk"},[_c('span',[_vm._v("發票載具")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vehicle),expression:"vehicle"}],attrs:{"type":"text","value":"","placeholder":""},domProps:{"value":(_vm.vehicle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.vehicle=$event.target.value}}})]),_c('table',[_vm._m(1),_c('tbody',_vm._l((_vm.rechargeMethodsList),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.sort))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v("$"+_vm._s(_vm._f("cleanDotFilter")(item.money)))]),_c('td',[_vm._v(_vm._s(_vm._f("cleanDotFilter")(item.correct_quantity)))]),_c('td',[(item.gift_quantity != 0)?_c('span',[_vm._v("贈送:"+_vm._s(_vm._f("coinName")(item.gift_currency))+" "+_vm._s(_vm._f("cleanDotFilter")(item.gift_quantity))+" 點")]):_vm._e(),(item.gift_quantity == 0)?_c('span',[_vm._v("無")]):_vm._e()]),_c('td',[_c('router-link',{staticClass:"buy",attrs:{"to":{
                    name: 'cbuydet',
                    params: {
                      id: item.id,
                      paytype: item.paytype,
                      vehicle: _vm.vehicle,
                    },
                  }}})],1)])}),0)])])]),_c('CompFoot')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tit"},[_c('h2',[_vm._v("結帳優惠")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("購買標題")]),_c('th',[_vm._v("金額")]),_c('th',[_vm._v("購買點數")]),_c('th',[_vm._v("贈送項目")]),_c('th',[_vm._v("購買")])])])}]

export { render, staticRenderFns }