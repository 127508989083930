<template>
  <div class="nr-mem-recbuysti">
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <CompMenNavmem :keyy="$route.name" />
        <div class="r-content" style="min-height: 600px">
          <h2>{{ this.menu | transMenuName(this.menuType) }}記錄</h2>
          <div class="btn">
            <router-link to="/mem/getsti"></router-link>
          </div>
          <table>
            <thead>
              <tr>
                <th style="width: 100px">編號</th>

                <th>張數</th>
                <th>贈送</th>
                <th>時間</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in stickerOrderList" :key="index">
                <td style="text-align: center">{{ item.single_number }}</td>

                <td>{{ item.quantity }}</td>
                <td v-if="Math.sign(item.quantity) !== -1">
                  {{ item.gift_currency | coinName }}
                  {{ item.gift_quantity }}
                </td>
                <td v-else />
                <td>{{ item.created_at }}</td>
              </tr>
            </tbody>
          </table>
          <CompPager
            v-if="pageData"
            @evtpagerchg="vodGetPage"
            :int-per-page="page.pagesize"
            :int-tol-cnt="pageData.allnums"
            :int-cur-page="page.currentPage"
            ref="pager"
          ></CompPager>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompPager: require("@/components/parts/pager").default,
    CompMenNavmem: require("@/components/parts/Navmem").default,
  },
  computed: {
    ...mapState({
      stickerOrderList: (state) => state.stickers.stickerOrderList,
      pageData: (state) => state.app.pageData,
      menu: (state) => state.website.menu,
    })
  },
  data() {
    const initPage = { pagesize: 10, currentPage: 1 };
    return {
      initPage,
      page: Object.assign({}, initPage),
      menuType: "gtrans",
    };
  },
  methods: {
    vodGetPage(page) {
      this.page.currentPage = page;
      this.getStickerOrder();
    },
    async getStickerOrder() {
      const data = {
        page: this.page.currentPage,
        pagesize: this.page.pagesize,
      };
      await this.$store.dispatch("stickers/getStickerOrder", data);
    },
  },
  async mounted() {
    await this.getStickerOrder();
  },
};
</script>
<style scoped>
@import "~@/assets/css/member-tzjl.css";
</style>
