import * as $api from "@/lib/api";
import Vue from "vue";



const actions = {
  async getMarketingList({ commit, dispatch }, _d) {
    let data = await $api.marketingApi.getMarketingList();
    return data
  },
};

export default {
  namespaced: true,
  actions
};
