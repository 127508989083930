import request from "@/plugins/request";

const baseUrl = "receiveaward";
function getAwardOrder(data) {
  return request({
    url: baseUrl + "/receive-award-order",
    method: "POST",
    data
  });
}
function pickupVirtualPrizes(data) {
  return request({
    url: baseUrl + "/winning-pickup0",
    method: "POST",
    data
  });
}
function eVoucher(data) {
  return request({
    url: baseUrl + "/winning-pickup1",
    method: "POST",
    data
  });
}
function pickupPhysicalPrizes(data) {
  return request({
    url: baseUrl + "/winning-pickup2",
    method: "POST",
    data
  });
}

export const receiveawardApi = {
  getAwardOrder,pickupVirtualPrizes,eVoucher,pickupPhysicalPrizes
};
