import request from "@/plugins/request";

const baseUrl = "precautions";
function getRechargeNoticeList(data) {
  return request({
    url: baseUrl + "/recharge-notices",
    method: "POST",
    data
  });
}
function getSmsNoticeList(data) {
  return request({
    url: baseUrl + "/sms-notices",
    method: "POST",
    data
  });
}
function getEmailNoticeList(data) {
  return request({
    url: baseUrl + "/email-notices",
    method: "POST",
    data
  });
}
function getActivityRuleList(data) {
  return request({
    url: baseUrl + "/activity-rules",
    method: "POST",
    data
  });
}
function getCheckoutNoticeList(data) {
  return request({
    url: baseUrl + "/checkout-notices",
    method: "POST",
    data
  });
}
function getShoppingRules(data) {
  return request({
    url: baseUrl + "/shopping-rules",
    method: "POST",
    data
  });
}

export const precautionsApi = {
  getRechargeNoticeList,
  getSmsNoticeList,
  getEmailNoticeList,
  getActivityRuleList,
  getCheckoutNoticeList,
  getShoppingRules
};
