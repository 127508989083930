import request from "@/plugins/request";

const baseUrl = "third";
function apiRoute(data) {
  return request({
    url: baseUrl + "/api-route",
    method: "POST",
    data
  });
}
function checkPhone(data) {
  return request({
    url: baseUrl + "/phone-check",
    method: "POST",
    data
  });
}

export const thirdApi = {
  apiRoute,
  checkPhone
};
