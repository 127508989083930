import request from "@/plugins/request";

const baseUrl = "Stickers";
function getStickersSettings(data) {
  return request({
    url: baseUrl + "/stickers-settings",
    method: "POST",
    data
  });
}
function getStickerOrder(data) {
  return request({
    url: baseUrl + "/sticker-order",
    method: "POST",
    data
  });
}
function checkoutSticker(data) {
  return request({
    url: baseUrl + "/sticker-checkout",
    method: "POST",
    data
  });
}
function pickupSticker(data) {
  return request({
    url: baseUrl + "/sticker-pickup",
    method: "POST",
    data
  });
}

export const stickersApi = {
  getStickersSettings,
  getStickerOrder,
  checkoutSticker,
  pickupSticker
};
