<template>
  <div v-if="paymentData" v-html="paymentData.html"></div>
</template>
<script>
import { getStorage, removeStorage } from "@/plugins/auth";

export default {
  name: "Payment",
  data() {
    return {
      paymentData: ""
    };
  },
  methods: {
    handleMessage(output) {
      let data = output.split("<script>");
      this.paymentData = {};
      let html = data[0];
      let _script = data[1].split("script>");
      let script = _script[0].slice(0, _script[0].length - 2);
      this.paymentData.html = html;
      removeStorage("payment");
      return script;
    },
    looseJsonParse(obj) {
      return Function(obj)();
    }
  },
  async mounted() {
    var payment = getStorage("payment");
    if (!payment) {
      this.$router.push({ name: "home" });
    } else {
      payment = JSON.parse(payment);
      let script = await this.handleMessage(payment.output);
      this.looseJsonParse(script);
    }
  }
};
</script>
