<template>
  <div class="game-list">
    <div class="center">
      <div class="tit">
        <h2>遊戲區</h2>
      </div>
      <CompMarquee />
      <div class="list">
        <ul>
          <li
            class="game-tn"
            v-for="(game, index) in gameList"
            :key="index"
            @click="getGameUrl(game.game_id)"
          >
            <div class="game-image-wrapper">
              <img class="game-img" :src="game.image" alt="" />
              <div class="game-btn"><span>進入遊戲</span></div>
            </div>
            <div class="game-text-wrapper">
              <span>{{ game.name }}</span>
            </div>
          </li>
          <!-- <li class="game-tn">
            <img class="game-img" src="assets/img/game-1.jpg" alt="" />
            <img class="game-n" src="assets/img/game-n.png" alt="" />
          </li>
          <li class="game-tn">
            <img class="game-img" src="assets/img/game-2.jpg" alt="" />
            <img class="game-n" src="assets/img/game-n.png" alt="" />
          </li>
          <li class="game-tn">
            <img class="game-img" src="assets/img/game-3.jpg" alt="" />
            <img class="game-n" src="assets/img/game-n.png" alt="" />
          </li>
          <li class="game-tn">
            <img class="game-img" src="assets/img/game-4.jpg" alt="" />
            <img class="game-n" src="assets/img/game-n.png" alt="" />
          </li> -->
        </ul>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "games",
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
  },
  computed: {
    ...mapState({
      gameList: (state) => state.msgaming.gameList,
      gameUrl: (state) => state.msgaming.gameUrl,
    }),
  },
  methods: {
    async getGameList() {
      await this.$store.dispatch("msgaming/getGameList");
    },
    async getGameUrl(game_id) {
      const loading = this.$loadingCase("連線中")
      const data = {
        token: sessionStorage.token,
        game_id,
        return_url: "",
        lang: "",
      };
      await this.$store.dispatch("msgaming/getGameUrl", data);
      loading.close();
      window.open(this.gameUrl, "_blank", " width=1300, height=900");
    },
  },
  created() {
    this.getGameList();
  },
};
</script>
<style scoped>
@import "~@/assets/css/game.css";
</style>
