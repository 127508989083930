<template>
  <div class="nr-dbuydet">
    <div class="center">
      <div class="tit">
        <h2>{{ this.menu | transMenuName(this.menuType) }}</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <div v-if="detail.pid && welfareData[1]" class="top-nr">
          <div class="limg">
            <img :src="detail.img" alt="" />
          </div>
          <div class="r-nr">
            <div class="top-txt">
              <h2>{{ detail.name }}</h2>
              <p class="yel">
                商品編號：{{ detail.maintype }}-{{ detail.pid }}
              </p>
              <p>商品分類：{{ detail.maintype_name }}</p>
              <p>熱門指數：{{ detail.hot }}</p>
            </div>
            <div class="btm-txt">
              <template v-if="!webMode">
                <div class="t1">
                  <input
                    type="radio"
                    name="useD"
                    value="1"
                    v-model="data.price_type"
                  />
                  福利價-1
                  <span class="red">
                    {{ welfareData[1].coin1 | coinName }}
                    {{ welfareData[1].value1 | toThousandFilter }}
                  </span>
                  <i>+</i>
                  <span class="green">
                    {{ welfareData[1].coin2 | coinName }}
                    {{ welfareData[1].value2 | toThousandFilter }}
                  </span>
                </div>
                <div class="t1">
                  <input
                    type="radio"
                    name="useD"
                    value="2"
                    v-model="data.price_type"
                  />
                  福利價-2
                  <select
                    style="
                      color: black;
                      padding: 0 6px;
                      line-height: 30px;
                      font-size: 18px;
                    "
                    name="selD"
                    v-model="welfareData[2].percent1"
                    v-on:change="vodCalDC"
                  >
                    <option
                      style="color: black"
                      v-for="(item, index) in welfarePrice2List"
                      :key="index"
                      :value="item.key"
                    >
                      {{ welfareData[2].coin1 | coinName }}{{ item.val }}%
                    </option>
                  </select>
                  <i>+</i>
                  <select
                    style="
                      color: black;
                      padding: 0 6px;
                      line-height: 30px;
                      font-size: 18px;
                    "
                    name="selC"
                    v-model="welfareData[2].percent2"
                    v-on:change="vodCalDC"
                  >
                    <option
                      style="color: black"
                      v-for="(item, index) in welfarePrice2List2"
                      :key="index"
                      :value="item.key"
                    >
                      {{ welfareData[2].coin2 | coinName }}{{ item.val }}%
                    </option>
                  </select>
                </div>
                <div class="t1" style="padding-left: 85px">
                  =
                  <span class="red">
                    {{ welfareData[2].coin1 | coinName }}
                    {{ welfareData[2].value1 | toThousandFilter }}
                  </span>
                  <i>+</i>
                  <span class="green" style="background: coral">
                    {{ welfareData[2].coin2 | coinName }}
                    {{ welfareData[2].value2 | toThousandFilter }}
                  </span>
                </div>
                <div class="t1">
                  <input
                    type="radio"
                    name="useD"
                    value="0"
                    v-model="data.price_type"
                  />
                  直購價
                  <span class="red">
                    ${{ detail.direct_price | toThousandFilter }}
                  </span>
                </div>
              </template>
              <div class="t2">
                購買數量
                <input type="number" v-model="data.qty" min="1" value="1" />個
                <span
                  class="give-away"
                  style="padding-left: 100px"
                  v-if="!webMode"
                  >贈
                  {{ detail.gift_price.coin | coinName }}
                  :
                  {{ detail.gift_price.value | toThousandFilter }}
                </span>
              </div>
              <!-- old -->
              <!-- <div class="t3">
                直購價
                <strong>${{ detail.direct_price | toThousandFilter }}</strong>
                <span style="padding-left: 100px" v-if="!webMode"
                  >贈
                  {{ detail.gift_price.coin | coinName }}
                  :
                  {{ detail.gift_price.value | toThousandFilter }}
                </span>
              </div> -->
              <a
                class="jr"
                v-on:click="vodAddCart(detail)"
                style="cursor: pointer; margin-top: 25px"
              >
                <img src="@/assets/img/jr-icon.png" alt="" />放入購物車
              </a>
            </div>
          </div>
        </div>
        <div v-if="detail.pid && welfareData[1]" class="js-nr">
          <h3>產品介紹</h3>
          <div class="js-xx" v-html="detail.description">
            <!-- {{ detail.description }} -->
          </div>
          <div class="js-xx">
            <a href="javascript:" @click="$router.go(-1)">回上頁</a>
          </div>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
  },
  computed: {
    ...mapGetters({
      loginState: "loginState",
      webMode: "webMode",
    }),
    ...mapState({
      coinList: (state) => state.coin.coinList,
      detail: (state) => state.product.detail,
      menu: (state) => state.website.menu,
    }),
  },
  data() {
    return {
      welfarePrice2List: [],
      welfarePrice2List2: [],
      welfareData: {},
      data: {
        price_type: 1, //(0:直購價、1:福利價1、2:福利價2)
        qty: 1,
      },
      menuType: "dbuy",
    };
  },
  methods: {
    getWelfarePrice2(percent, coinCode) {
      const coin1 = this.coinList.find(
        (x) => x.code === this.welfareData[2].coin1
      );
      const coin2 = this.coinList.find(
        (x) => x.code === this.welfareData[2].coin2
      );
      let a = Math.ceil(
        (((this.detail.purchase_price * this.detail.welfare_ratio) / 100) *
          this.welfareData[2].percent1) /
          coin1.value
      );
      let b = Math.ceil(
        (this.detail.purchase_price * this.detail.welfare_ratio -
          a * coin1.value) /
          coin2.value
      );
      this.welfareData[2].value1 = a;
      this.welfareData[2].value2 = b;
    },
    async getCoinList() {
      await this.$store.dispatch("coin/getCoinList");
    },
    async getDetail(id) {
      await this.$store.dispatch("product/getDetail", { direct_id: id });
      this.data.pid = this.detail.pid;
      this.data.direct_id = this.detail.id;
      this.welfareData = {
        0: {
          coin1: "T",
          value1: this.detail.direct_price,
          coin2: "T",
          value2: "",
        },
        1: {
          coin1: this.detail.welfare_price1[0].coin,
          percent1: this.detail.welfare_price1[0].percent,
          value1: this.detail.welfare_price1[0].value,
          coin2: this.detail.welfare_price1[1].coin,
          percent2: this.detail.welfare_price1[1].percent,
          value2: this.detail.welfare_price1[1].value,
        },
        2: {
          coin1: this.detail.welfare_price2[0].coin,
          percent1: this.detail.welfare_price2[0]["percent-min"],
          value1: this.detail.welfare_price2[0].value,
          coin2: this.detail.welfare_price2[1].coin,
          percent2: this.detail.welfare_price2[1]["percent-max"],
          value2: this.detail.welfare_price2[1].value,
        },
      };
      //計算福利價２百分比 幣種１
      for (
        let i = Number(this.detail.welfare_price2[0]["percent-min"]);
        i < Number(this.detail.welfare_price2[0]["percent-max"]) + 1;
        i++
      ) {
        i = Number(i);
        this.welfarePrice2List.push({
          val: i,
          key: i,
        });
      }
      //計算福利價２百分比 幣種２
      for (
        let i = Number(this.detail.welfare_price2[1]["percent-min"]);
        i < Number(this.detail.welfare_price2[1]["percent-max"]) + 1;
        i++
      ) {
        i = Number(i);
        this.welfarePrice2List2.push({
          val: i,
          key: i,
        });
      }
    },
    vodCalDC(evt) {
      if (evt) {
        switch (evt.target.name) {
          case "selD":
            this.welfareData[2].percent2 = 100 - this.welfareData[2].percent1;
            this.getWelfarePrice2();
            break;
          case "selC":
            this.welfareData[2].percent1 = 100 - this.welfareData[2].percent2;
            this.getWelfarePrice2();
            break;
        }
      }
    },
    vodAddCart(detail) {
      if (!this.loginState) {
        this.$snotify.error("未登入會員不能購買商品");
        return;
      }
      this.data.qty = parseInt(this.data.qty);
      if (!this.data.qty > 0) {
        return;
      }
      let welfareData = this.data.price_type
        ? this.welfareData[this.data.price_type]
        : "";
      const item = Object.assign(this.data, welfareData);
      this.$store.dispatch("product/addCartList", {
        item,
        detail: Object.assign({}, this.detail),
      });
    },
  },
  async mounted() {
    await this.getCoinList();
    let id = parseInt(this.$route.params.id);
    await this.getDetail(id);
    this.data.qty = 1;
  },
};
</script>
<style scoped>
@import "~@/assets/css/dp-details.css";
.give-away {
  padding-left: 100px;
  color: #7a00a7;
  font-size: 20px;
  margin-top: 15px;
}
</style>
