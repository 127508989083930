<template>
  <div v-if="intTolPage" id="pager" class="page-con">
    <div class="page-left">
      <a
        :class="{ disable: intCurPage <= 1 }"
        @click="vodFirst"
      ><img
        src="@/assets/img/r-btn.png"
      ></a>
      <a
        :class="{ disable: intCurPage <= 1 }"
        @click="vodPrev"
      ><img
        src="@/assets/img/p-btn.png"
      ></a>
      <!-- 頁數 -->
      <a
        v-for="(item, index) in Array(intTolPage).fill(0)"
        :key="index"
        :class="['clsPage', 'clsPage' + (index + 1)]"
        @click="vodSetPage(index + 1)"
      >{{ index + 1 }}</a>
      <input
        v-model.trim="pageNumber"
        class="page-search"
        type="number"
        @keyup.enter="pageSearch"
      >
    </div>
    <div class="page-right">
      <a
        :class="{ disable: intCurPage >= intTolPage }"
        @click="vodFinal"
      ><img
        src="@/assets/img/l-btn.png"
      ></a>
      <a
        :class="{ disable: intCurPage >= intTolPage }"
        @click="vodNext"
      ><img
        src="@/assets/img/n-btn.png"
      ></a>
      <p>總頁數{{ intCurPage }}/{{ intTolPage }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CompPager',
  // intPerPage(一頁幾個)、intTolCnt(總共幾個)、intCurPage(現在頁數)
  props: ['intPerPage', 'intTolCnt', 'intCurPage'],
  data () {
    return {
      intTolPage: 1,
      pageNumber: ''
    }
  },
  watch: {
    intCurPage (v) {
      this.vodPageDis()
      this.pageNumber = this.intCurPage
    },
    intTolCnt: {
      immediate: true,
      handler (v, ov) {
        this.intTolPage = Math.ceil(this.intTolCnt / this.intPerPage)
        this.vodPageDis()
      }
    },
    pageNumber (next, prev) {
      if (Number(next) > Number(this.intTolPage)) {
        this.pageNumber = this.intTolPage
      } else if (next !== '' && Number(next) === 0) {
        this.pageNumber = 1
      }
    }
  },
  mounted () {},
  methods: {
    pageSearch () {
      if (!this.pageNumber) {
        this.$snotify.error('請輸入頁碼')
        return
      }
      this.$emit('evtpagerchg', Number(this.pageNumber))
    },
    vodPrev () {
      if (this.intCurPage > 1) {
        this.$emit('evtpagerchg', Number(this.intCurPage) - 1)
        this.vodPageDis()
      }
    },
    vodNext () {
      if (this.intCurPage < this.intTolPage) {
        this.$emit('evtpagerchg', Number(this.intCurPage) + 1)
        this.vodPageDis()
      }
    },
    vodFinal () {
      this.$emit('evtpagerchg', this.intTolPage)
      this.vodSetPage(this.intTolPage)
    },
    vodFirst () {
      this.$emit('evtpagerchg', 1)
      this.vodSetPage(1)
    },
    vodSetPage (int) {
      this.$emit('evtpagerchg', int)
      // this.vodPageDis();
      this.vodPageRen()
    },
    vodPageDis () {
      setTimeout(() => {
        $(this.$el)
          .find('.clsPage')
          .removeClass('on')
        $(this.$el)
          .find('.clsPage')
          .hide()
        $(this.$el)
          .find('.clsPage' + this.intCurPage)
          .show()
        $(this.$el)
          .find('.clsPage' + this.intCurPage)
          .addClass('on')
        Array(3)
          .fill(0)
          .map((v, i) => {
            $(this.$el)
              .find('.clsPage' + (this.intCurPage + i))
              .show()
            $(this.$el)
              .find('.clsPage' + (this.intCurPage - i))
              .show()
          })
        this.vodPageRen()
      }, 500)
      // this.$nextTick(() => {
      //   $(this.$el)
      //     .find('.clsPage')
      //     .removeClass('on')
      //   $(this.$el)
      //     .find('.clsPage')
      //     .hide()
      //   $(this.$el)
      //     .find('.clsPage' + this.intCurPage)
      //     .show()
      //   $(this.$el)
      //     .find('.clsPage' + this.intCurPage)
      //     .addClass('on')
      //   Array(3)
      //     .fill(0)
      //     .map((v, i) => {
      //       $(this.$el)
      //         .find('.clsPage' + (this.intCurPage + i))
      //         .show()
      //       $(this.$el)
      //         .find('.clsPage' + (this.intCurPage - i))
      //         .show()
      //     })
      //   this.vodPageRen()
      // })
    },
    vodPageRen () {
      this.aryCoin = []
      const intBase = this.intPerPage * (this.intCurPage - 1)
    }
  }
}
</script>
