<template>
  <div>
    <div ref="flexslider1" class="banner flexslider">
      <el-carousel
        :interval="seoSettings.ad_carousel * 1000 || 3000"
        class="slides"
        :height="adImgheight + 'px'"
      >
        <el-carousel-item v-for="(item, key) in homeAdList" :key="key">
          <img
            ref="adImg"
            :src="$baseImgUrl + item.image"
            alt="key"
            width="100%"
            @click="openAD(item.url)"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!---->
    <div class="ct-list">
      <div class="center">
        <CompMarquee />
        <div class="zs" v-if="!webMode">
          <div class="bg">
            <div class="top-bg"></div>
            <div class="l-bd"></div>
            <div class="r-bd"></div>
            <div class="btm-bg"></div>
            <CompLottItem :lottList="hotlist.list" />
          </div>
        </div>
        <div class="nr-dbuy" v-else>
          <div class="center">
            <div class="list">
              <CompProductItem />
            </div>
          </div>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DeviceUUID from "device-uuid";

export default {
  name: "Home",
  props: {},
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompProductItem: require("@/components/productItem").default,
    CompLottItem: require("@/components/LottItem").default,
  },
  computed: {
    ...mapState({
      seoSettings: (state) => state.website.seoSettings,
      homeAdList: (state) => state.image.homeAdList,
      welcomeImg: (state) => state.image.welcomeImg,
      welcomeFlag: (state) => state.image.welcomeFlag,
      welcomeTimer: (state) => state.image.welcomeTimer,
      productList: (state) => state.product.productList,
      hotlist: (state) => state.activity.hotlist,
    }),
    ...mapGetters({
      loginState: "loginState",
      webMode: "webMode",
    }),
  },
  data() {
    return {
      adImgheight: document.body.clientWidth / 4.6,
    };
  },
  methods: {
    async shareView(link) {
      const data = {
        link,
        uuid: new DeviceUUID.DeviceUUID().get(),
      };
      await this.$store.dispatch("member/shareView", data);
    },
    reportWindowSize() {
      this.adImgheight = document.body.clientWidth / 4.6;
    },
    async getHomeAdList() {
      await this.$store.dispatch("image/getHomeAdList");
    },
    async getType() {
      await this.$store.dispatch("product/getType");
    },
    async getProduct() {
      await this.$store.dispatch("product/getProduct");
    },
    async getHotlist() {
      const data = {
        count: 12,
      };
      await this.$store.dispatch("activity/hotlist", data);
    },
    openAD(url) {
      url && (location.href = url);
    },
    async getWelcomeImg() {
      // 第一次進入網站
      if (!this.welcomeFlag) {
        await this.$store.dispatch("image/getWelcomeImg");
      }
      // 若沒有計時器
      if (this.welcomeTimer === "") {
        this.$store.state.image.welcomeTimer = setInterval(() => {
          // 在ＨＯＭＥ才顯是
          if (this.$route.name === "home") {
            this.$store.dispatch("image/getWelcomeImg");
          }
        }, 3600000);
      }
    },
  },
  async mounted() {
    if (this.$route.params.parent) {
      localStorage.parent = this.$route.params.parent || "";
      this.shareView(this.$route.params.parent);
    }
    await this.getWelcomeImg();
    await this.getHomeAdList();
    if (!this.webMode) {
      await this.getHotlist();
    } else {
      await this.getType();
      await this.getProduct();
    }
    window.addEventListener("resize", this.reportWindowSize);
  },
};
</script>

<style scoped></style>
