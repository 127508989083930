import request from "@/plugins/request";

const baseUrl = "product";
function getProduct(data) {
  return request({
    url: baseUrl + "/getproduct",
    method: "POST",
    data
  });
}
function getType(data) {
  return request({
    url: baseUrl + "/gettype",
    method: "POST",
    data
  });
}
function getDetail(data) {
  return request({
    url: baseUrl + "/getdetail",
    method: "POST",
    data
  });
}

export const productApi = {
  getProduct,
  getType,
  getDetail
};
