import * as $api from "@/lib/api";

const state = {
  swardOrderList: []
};

const mutations = {
  setAwardOrder: (state, data) => {
    state.swardOrderList = data;
  }
};

const actions = {
  async getAwardOrder({ commit, dispatch }, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.receiveawardApi.getAwardOrder(_d);
    commit("setAwardOrder", data.data.list);
    await commit(
      "app/setPageData",
      {
        allnums: data.data.allnums,
        page: data.data.page,
        pagesize: data.data.pagesize
      },
      { root: true }
    );
  },
  async pickupVirtualPrizes({ commit, dispatch }, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.receiveawardApi.pickupVirtualPrizes(_d);
    if (data.status) {
      return data.status;
    }
  },
  async pickupPhysicalPrizes({ commit, dispatch }, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.receiveawardApi.pickupPhysicalPrizes(_d);
    if (data.status) {
      return data.status;
    }
  },
  async eVoucher({ commit, dispatch }, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.receiveawardApi.eVoucher(_d);
    if (data.status) {
      return data.status;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
