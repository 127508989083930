import $store from '@/store/'
import moment from 'moment'

export const date = val => moment(val).format('YYYY-MM-DD')
export const relativeDate = val =>
  moment()
    .subtract('days', val)
    .format('YYYY-MM-DD')
export const timeSeconds = val =>
  moment('1900-01-01 00:00:00')
    .add(val, 'seconds')
    .format('HH:mm:ss')
export const diffDates = (val) => {
  const startDate = moment(val.split(' ')[0])
  const endDate = moment(relativeDate(0))
  if (startDate === endDate) {
    return 0
  } else {
    const years = endDate.diff(startDate, 'years')
    const months = endDate.diff(startDate, 'months') - years * 12
    startDate.add(years, 'years').add(months, 'months')
    const days = endDate.diff(startDate, 'days')
    return days
  }
}

export function coinName (code) {
  if (code === 'T') {
    return code
  }
  if (code) {
    const coinList = $store.state.coin.coinList
    if (coinList.length > 0) {
      const data = coinList.find(x => x.code === code)
      return data.name
    }
  }
}

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize (time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo (time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter (num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
        si[i].symbol
      )
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter (num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Lower case first char
 * @param {String} string
 */
export function lowercaseFirst (string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

/**
 * 10 => "10.00"
 * @param {number} num
 */
export function toDecimalFilter (num) {
  return (+num || 0).toString().split('.').length > 1 ? num : `${num.toString()}.00`
}
/**
 * 10.00 => 10
 * @param {String} string
 */
export function cleanDotFilter (string) {
  const str = '' + string
  const index = str.indexOf('.')
  if (index !== -1) {
    return str.substr(0, str.indexOf('.'))
  } else {
    return str
  }
}
/**
 * 小數點後三位趴數
 * 0.1010% => 0.101%
 * @param {String} string
 */
export function transPercentage (string) {
  if (!string) {
    return ''
  } else {
    return string.substr(0, string.indexOf('.') + 4) + '%'
  }
}

/**
 * menu name 轉換
 * @param {Array} menu
 * @param {String} menuType
 */
export function transMenuName (menu, menuType) {
  if (menu.length === 0) {
    return
  }
  return menu.filter(e => e.code === menuType)[0].name
}
