<template>
  <div class="nr-cartc">
    <div class="center">
      <div class="tit">
        <h2>購買專區</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <div class="step-con">
          <img src="@/assets/img/bc-step-2.png" alt="" />
        </div>
        <h3>交易明細</h3>
        <CompCartTable
          :cartList="cartList"
          :cartDetailList="cartDetailList"
          :cartTotalList="cartTotalList"
          :strBType="strBType"
          :showDel="false"
        />
        <!-- <div style="max-height: 600px; overflow-y: auto">
          <table>
            <thead>
              <tr>
                <th>商品名稱</th>
                <th style="width: 200px">價格</th>
                <th>數量</th>
                <th style="width: 200px">小計</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in cartList" :key="index">
                <td>
                  <img
                    :src="cartDetailList[index].img"
                    alt=""
                    style="height: 88px"
                  />
                  <span>{{ cartDetailList[index].name }}</span>
                </td>
                <td v-if="!strBType">
                  <template v-if="item.coin1 !== 'T'">
                    <p>
                      {{ item.coin1 | coinName }}：${{
                        item.value1 | toThousandFilter
                      }}
                    </p>
                    <p>
                      {{ item.coin2 | coinName }}：${{
                        item.value2 | toThousandFilter
                      }}
                    </p>
                  </template>
                  <template v-else>
                    <p>${{ item.value1 | toThousandFilter }}</p>
                  </template>
                </td>
                <td v-else>
                  ${{ cartDetailList[index].direct_price | toThousandFilter }}
                </td>
                <td>
                  {{ item.qty }}
                </td>
                <td v-if="!strBType">
                  <template v-if="item.coin1 !== 'T'">
                    <p>
                      {{ item.coin1 | coinName }}：${{
                        (item.value1 * item.qty) | toThousandFilter
                      }}
                    </p>
                    <p>
                      {{ item.coin2 | coinName }}：${{
                        (item.value2 * item.qty) | toThousandFilter
                      }}
                    </p>
                  </template>
                  <template v-else>
                    <p>${{ (item.value1 * item.qty) | toThousandFilter }}</p>
                  </template>
                </td>
                <td v-else>
                  ${{
                    (cartDetailList[index].direct_price * item.qty)
                      | toThousandFilter
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
        <!-- <div class="pri">
          <div class="l-jg">應繳金額</div>
          <div class="r-jg" v-if="!strBType">
            <template v-if="cartTotalList.length > 0">
              <p v-for="(item, key) in cartTotalList" :key="key">
                <span v-if="item.coin !== 'T'">
                  {{ item.coin | coinName }}：${{
                    item.value | toThousandFilter
                  }}
                </span>
                <span v-else> ${{ item.value | toThousandFilter }} </span>
              </p>
            </template>
            <p v-else>$ 0</p>
          </div>
          <div class="r-jg" v-else>
            <template v-if="cartTotalList.length > 0">
              <p>${{ calculateCartDirectTotal() | toThousandFilter }}</p>
            </template>
            <p v-else>$ 0</p>
          </div>
        </div> -->
        <div class="mod" v-if="strBType || showPaymentList">
          <strong>選擇付款方式</strong>
          <el-radio-group v-model="data.paytype">
            <el-radio
              :label="item.id"
              :key="item.id"
              v-for="item in paymentMethodList"
            >
              <span>{{ item.name }}</span>
              <span
                >手續費：
                <template v-if="!item.feetype">$</template>
                {{ item.fee || 0 | toThousandFilter }}
                <template v-if="item.feetype">%</template>
              </span>
            </el-radio>
          </el-radio-group>
        </div>
        <ReceiveInfoList :data="data" />
        <div class="btn-ct">
          <a class="jxjz" style="cursor: pointer" v-on:click="vodToCart"
            >上一步</a
          >
          <a class="ljjz" style="cursor: pointer" v-on:click="vodSend()"
            >完成確認</a
          >
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { setStorage } from "@/plugins/auth";

export default {
  components: {
    CompCartTable: require("@/components/CartTable").default,
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    ReceiveInfoList: require("@/components/ReceiveInfoList").default,
  },
  computed: {
    ...mapGetters({
      loginState: "loginState",
      webMode: "webMode",
      cartList: "product/cartList",
      cartDetailList: "product/cartDetailList",
    }),
    ...mapState({
      userInfo: (state) => state.member.userInfo,
      _paymentMethodList: (state) => state.other.paymentMethodList,
      cartTotalList: (state) => state.product.cartTotalList,
    }),
  },
  data() {
    let initData = {
      receive_name: "",
      receive_phone: "",
      receive_email: "",
      receive_address: "",
      receive_time: "不指定時間",
      paytype: "",
      remarks: "",
      vehicle: "",
    };
    return {
      initData,
      data: Object.assign({}, initData),
      strBType: this.$route.params.strBType * 1,
      showPaymentList: this.$route.params.showPaymentList*1,
      paymentMethodList: [],
    };
  },
  methods: {
    vodToCart() {
      this.$router.push({ name: "cart" });
    },
    async getPaymentMethodList() {
      await this.$store.dispatch("other/getPaymentMethodList");
      this.paymentMethodList = this._paymentMethodList.filter(
        (x) => x.dir_pay === 1
      );
      this.data.paytype = this.paymentMethodList[0].id;
    },
    calculateCartTotal() {
      this.$store.dispatch("product/calculateCartTotal", this.cartList);
    },
    calculateCartDirectTotal() {
      return this.cartDetailList.reduce(
        (obj, next, i) =>
          obj + Number(next.direct_price) * this.cartList[i].qty,
        0
      );
    },
    vodSend() {
      let objChk = {
        receive_name: { strMsg: "姓名未填" },
        receive_phone: { strMsg: "電話未填" },
        receive_address: { strMsg: "地址未填" },
      };
      for (let k in this.data) {
        if (objChk[k]) {
          if (this.data[k] == "") {
            this.$snotify.error(objChk[k]["strMsg"]);
            return;
          }
        }
      }
      if (this.cartList.length == 0) {
        this.$snotify.error("請先選擇欲購商品");
        return;
      }
      this.send();
    },
    async send() {
      if (!this.strBType) {
        //沒混台幣
        if (!this.showPaymentList) {
          this.data.paytype = 0;
        }
        this.data.list = JSON.stringify(this.cartList);
      } else {
        let list = this.cartList.map((x) => {
          return { direct_id: x.direct_id, price_type: 0, qty: x.qty };
        });
        this.data.list = JSON.stringify(list);
      }
      localStorage.lastCartList = JSON.stringify(this.cartList);
      localStorage.lastCartDetailList = JSON.stringify(this.cartDetailList);
      localStorage.lastCartTotalList = JSON.stringify(this.cartTotalList);
      const loading = this.$loadingCase("訂單建立中")
      const state = await this.$store.dispatch(
        "directpurchase/checkoutDirectpurchase",
        this.data
      );
      loading.close();
      if (state.status) {
        this.$store.dispatch("recharge/connectRechargeLink", state.data);
        this.$snotify.info("訂單送出成功");
        this.$router.push({
          name: "cartFinal",
          params: {
            strBType: this.strBType,
          },
        });
      }
    },
  },
  mounted() {
    this.getPaymentMethodList();
    this.calculateCartTotal();
  },
};
</script>
<style scoped>
@import "~@/assets/css/buy-car-2.css";
::v-deep .el-radio__input {
  vertical-align: bottom;
}
</style>
