<template>
  <div class="ipt-ct" style="display: flex;">
    <div style="width:90.5%">
      <label>
        <input
          :ref="keyy"
          type="radio"
          class="xx"
          v-model="data[keyy]"
          value="1"
          name="rdoGender"
          :checked="data[keyy] === 1"
          :disabled="!dataDisState[keyy]"
        />
        男性
      </label>
      <label>
        <input
          :ref="keyy"
          type="radio"
          class="xx"
          v-model="data[keyy]"
          value="0"
          name="rdoGender"
          :checked="data[keyy] === 0"
          :disabled="!dataDisState[keyy]"
        />
        女性
      </label>
    </div>
    <input
      :ref="'edit_' + keyy"
      type="button"
      value="編輯"
      v-if="!dataDisState[keyy]"
      style="width: 5%;padding-left: 0px;cursor: pointer;"
      @click="$emit('vodEdit', keyy)"
    />
    <input
      :ref="'edit_' + keyy"
      type="button"
      value="儲存"
      v-else
      style="width: 5%;padding-left: 0px;cursor: pointer;background:#883b3b"
      @click="$emit('vodSave', keyy)"
    />
  </div>
  <!-- <div class="ipt-ct">
    <input :ref="keyy" type="text" v-model="data[keyy]" :disabled="!dataDisState[keyy]" />
    <input
      :ref="'edit_' + keyy"
      type="button"
      value="編輯"
      v-if="!dataDisState[keyy]"
      style="width: 5%;padding-left: 0px;cursor: pointer;"
      @click="$emit('vodEdit', keyy)"
    />
    <input
      :ref="'edit_' + keyy"
      type="button"
      value="儲存"
      v-else
      style="width: 5%;padding-left: 0px;cursor: pointer;background:#883b3b"
      @click="$emit('vodSave', keyy)"
    />
  </div> -->
</template>

<script>
export default {
  name: "InputRadio",
  props: {
    keyy: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    dataDisState: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.$bus.on("input.blur" + this.keyy, async str => {
      await this.$refs["edit_" + str].blur();
    });
    this.$bus.on("input.focus" + this.keyy, async str => {
      await this.$refs[str].focus();
    });
  },
  destroyed() {
    this.$bus.off("input.blur" + this.keyy);
    this.$bus.off("input.focus" + this.keyy);
  }
};
</script>

<style></style>
