import router from '@/router'
import store from '@/store'
import axios from 'axios'
import Vue from 'vue'

const url = location.host.includes('hope178') || location.host.includes('0808178')
  ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_TEST_BASE_API
const service = axios.create({
  baseURL: url,
  withCredentials: false,
  timeout: 10000
})

service.interceptors.request.use(
  (config) => {
    const token = sessionStorage.token ? sessionStorage.token : ''
    if (token) {
      config.headers.authorization = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    if (response.headers.authorization) {
      sessionStorage.token = response.headers.authorization
    }
    if (response.status === 200) {
      if (response.data.data.token) {
        sessionStorage.token = response.data.data.token
      }
      if (response.data.msg) {
        let showSnotify = true
        if (response.data.msg === '無維護') { return }
        if (response.request.responseURL.includes('readmessage')) { return }
        if (response.config.url === 'third/api-route' || response.data.msg === '操作成功' || response.data.msg === '') {
          showSnotify = false
        }

        // 只顯示一次
        if (Vue.prototype.$snotify.notifications.length === 0) {
          if (response.data.status) {
            showSnotify && Vue.prototype.$snotify.success(response.data.msg)
          } else {
            Vue.prototype.$snotify.error(response.data.msg)
          }
        }
      }
      //
      if (response.data && response.data.status) {
        return response.data
      } else {
        return response.data.status
      }
    } else {
      response.err = true
      return response
    }
  },
  (error) => {
    switch (error.response.status) {
      case 404:
        // router.push("/login");
        break
      case 401:
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('wait')
        store.commit('login/toggleLogin')
        if (router.history.current.name === 'Login') {
          if (!error.response.data.status) {
            store.dispatch('validate/openErrorModal', error.response.data.message)
          }
        } else {
          router.push('/')
        }
        break
      case 403:
        break
      case 422:
        if (!error.response.data.status) {
          store.dispatch('validate/openErrorModal', error.response.data.message)
        }
        break
      default:
        break
    }

    return Promise.reject(error)
  }
)

export default service
