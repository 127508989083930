import request from "@/plugins/request";

const baseUrl = "msgaming";
function getGameList(data) {
  return request({
    url: baseUrl + "/list",
    method: "POST",
    data
  });
}
function getGameUrl(data) {
  return request({
    url: baseUrl + "/gameurl",
    method: "POST",
    data
  });
}

export const msgamingApi = {
  getGameList,
  getGameUrl
};
