import { validate } from "@/lib/validate/index";
import { getStorage, removeStorage } from "@/plugins/auth";
import Vue from "vue";

const state = {
  loginState: sessionStorage.token ? true : false,
  pageData: {}
};

const getters = {};

const mutations = {
  toggleLogin: (state, status) => {
    state.loginState = sessionStorage.token ? true : false;
  },
  setPageData: (state, data) => {
    state.pageData = data;
  }
};

const actions = {
  async vodMsg({ commit }, { ttl = "", cont = "", type = "error" }) {
    Vue.prototype.$snotify[type](cont, ttl);
  },
  clsConfirm({ commit }, { strTTL, strCont, func }) {
    Vue.prototype.$snotify.confirm(strTTL, strCont, {
      position: "centerCenter",
      buttons: [
        {
          text: "確定",
          bold: false,
          action: toast => {
            Vue.prototype.$snotify.remove(toast.id);
            func();
          }
        },
        {
          text: "取消",
          action: toast => {
            Vue.prototype.$snotify.remove(toast.id);
          },
          bold: true
        }
      ]
    });
  },
  addToken({ commit }, _d = {}) {
    const token = sessionStorage.token ? sessionStorage.token : "";
    _d.token = token;
    return _d;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
