<template>
  <div class="l-nav">
    <router-link
      id="r_info"
      to="/mem/info"
      :class="{ act: keyy === 'memberInfo' }"
    >
      基本資料 <i>></i>
    </router-link>
    <router-link
      :class="{ act: keyy === 'memberAuth' }"
      id="r_auth"
      to="/mem/auth"
    >
      認證中心 <i>></i>
    </router-link>
    <router-link
      :class="{ act: keyy === 'memberRecbuydri' }"
      id="r_recbuydri"
      to="/mem/recbuydri"
    >
      直購商品記錄 <i>></i>
    </router-link>
    <router-link
      :class="{ act: keyy === 'memberRecbuysti' }"
      id="r_recbuysti"
      to="/mem/recbuysti"
      v-if="!webMode"
    >
      直購貼紙記錄 <i>></i>
    </router-link>
    <router-link :class="{ act: keyy === 'memberReclot' }" id="r_reclot" to="/mem/reclot" v-if="!webMode">
      中獎紀錄/提領 <i>></i>
    </router-link>
    <router-link
      :class="{ act: keyy === 'memberRecpritake' }"
      id="r_recpritake"
      to="/mem/recpritake"
      v-if="!webMode"
    >
      領獎紀錄/訂單 <i>></i>
    </router-link>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "CompMenNavmem",
  props: {
    keyy: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      webMode: "webMode",
    }),
  },
  mounted() {
    // let seltor = document.getElementsByClassName("l-nav");
    // let dom = seltor[0];
    // let ary = dom.querySelectorAll("a");
    // for (var i in ary) {
    //   ary[i].classList = [];
    // }
    // var domTar = document.getElementById("r_" + location.hash.split("/").pop());
    // if (domTar) {
    //   domTar.classList = ["act"];
    // }
  },
};
</script>
