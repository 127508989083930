<template>
  <div class="nr-cart">
    <div class="center">
      <div class="tit">
        <h2>購買專區</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <div class="step-con">
          <img src="@/assets/img/bc-step-1.png" alt="" />
        </div>
        <h3>交易明細</h3>
        <CompCartTable
          :cartList="cartList"
          :cartDetailList="cartDetailList"
          :cartTotalList="cartTotalList"
          :strBType="strBType"
          :showDel="true"
        />
        <!-- <div style="min-height: 500px; max-height: 1000px; overflow-y: auto">
          <table>
            <thead>
              <tr>
                <th>商品名稱</th>
                <th>價格</th>
                <th>數量</th>
                <th>小計</th>
                <th>刪除</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in cartList" :key="index">
                <td>
                  <img
                    :src="cartDetailList[index].img"
                    alt=""
                    style="height: 88px"
                  />
                  <span>{{ cartDetailList[index].name }}</span>
                </td>
                <td v-if="!strBType">
                  <template v-if="item.coin1 !== 'T'">
                    <p>
                      {{ item.coin1 | coinName }}：${{
                        item.value1 | toThousandFilter
                      }}
                    </p>
                    <p>
                      {{ item.coin2 | coinName }}：${{
                        item.value2 | toThousandFilter
                      }}
                    </p>
                  </template>
                  <template v-else>
                    <p>${{ item.value1 | toThousandFilter }}</p>
                  </template>
                </td>
                <td v-else>
                  ${{ cartDetailList[index].direct_price | toThousandFilter }}
                </td>
                <td>
                  {{ item.qty }}
                </td>
                <td v-if="!strBType">
                  <template v-if="item.coin1 !== 'T'">
                    <p>
                      {{ item.coin1 | coinName }}：${{
                        (item.value1 * item.qty) | toThousandFilter
                      }}
                    </p>
                    <p>
                      {{ item.coin2 | coinName }}：${{
                        (item.value2 * item.qty) | toThousandFilter
                      }}
                    </p>
                  </template>
                  <template v-else>
                    <p>${{ (item.value1 * item.qty) | toThousandFilter }}</p>
                  </template>
                </td>
                <td v-else>
                  ${{
                    (cartDetailList[index].direct_price * item.qty)
                      | toThousandFilter
                  }}
                </td>
                <td>
                  <i
                    class="del"
                    v-on:click="vodDelete(cartDetailList[index], index)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
        <!-- <div class="mod">
          <strong>結帳模式</strong>
          <label v-if="webMode">
            <input type="radio" name="1" v-model="strBType" :value="1" />
            直購價
          </label>
          <label v-else>
            <input type="radio" name="1" v-model="strBType" :value="0" />
            福利價
          </label>
        </div> -->
        <!-- <div class="pri">
          <div class="l-jg">應繳金額</div>
          <div class="r-jg" v-if="!strBType">
            <template v-if="cartTotalList.length > 0">
              <p v-for="(item, key) in cartTotalList" :key="key">
                <span v-if="item.coin !== 'T'">
                  {{ item.coin | coinName }}：${{
                    item.value | toThousandFilter
                  }}
                </span>
                <span v-else> ${{ item.value | toThousandFilter }} </span>
              </p>
            </template>
            <p v-else>$ 0</p>
          </div>
          <div class="r-jg" v-else>
            <template v-if="cartTotalList.length > 0">
              <p>${{ calculateCartDirectTotal() | toThousandFilter }}</p>
            </template>
            <p v-else>$ 0</p>
          </div>
        </div> -->
        <div class="btn-ct">
          <router-link class="jxjz" to="/dbuy">繼續購物</router-link>
          <a
            class="ljjz"
            v-on:click="vodNextStep"
            v-if="cartList.length != 0"
            style="cursor: pointer"
            >立即結帳</a
          >
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    CompCartTable: require("@/components/CartTable").default,
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
  },
  computed: {
    ...mapGetters({
      loginState: "loginState",
      webMode: "webMode",
      cartList: "product/cartList",
      cartDetailList: "product/cartDetailList",
    }),
    ...mapState({
      userInfo: (state) => state.member.userInfo,
      cartTotalList: (state) => state.product.cartTotalList,
    }),
  },
  data() {
    return {
      // 當前頁面使用，避免影響全局
      strBType: this.$store.getters.webMode,
    };
  },
  methods: {
    vodNextStep() {
      const showPaymentList = this.cartTotalList.some((e) => e.coin === "T")
        ? 1
        : 0;
      let maxCountFlag = false;
      // 檢查商品是否超過最大購買數量
      let productNum = {};
      this.cartList.forEach((e) => {
        productNum[`pid${e.pid}`] =
          productNum[`pid${e.pid}`] == undefined
            ? 1
            : productNum[`pid${e.pid}`] + e.qty;
      });
      this.cartDetailList.forEach((e) => {
        if (!maxCountFlag && productNum[`pid${e.pid}`] > e.max_count) {
          this.$snotify.error(`商品${e.name}，單次限購${e.max_count}個`);
          maxCountFlag = true;
        }
      });
      if(maxCountFlag) return
      // 檢查商品是否超過最大購買數量
      if (this.cartList.length == 0) {
        this.$snotify.error("請先添加商品");
        return;
      }
      if (this.loginState === false) {
        this.$snotify.error("請先登入");
        return;
      }
      if (!this.strBType) {
        for (let x of this.cartTotalList) {
          if (this.userInfo[x.coin] < x.value) {
            this.nextError = true;
            this.$snotify.error("餘額不足");
            break;
          }
        }
        if (!this.nextError) {
          this.$router.push({
            name: "cartConfirm",
            params: { strBType: this.strBType, showPaymentList },
          });
        }
      } else {
        this.$router.push({
          name: "cartConfirm",
          params: { strBType: this.strBType, showPaymentList },
        });
      }
    },
    vodDelete(obj, key) {
      this.$snotify.confirm("是否確定刪除 " + obj.name, null, {
        buttons: [
          {
            text: "是",
            action: (obj) => {
              this.$store.dispatch("product/removeCartList", key);
              this.calculateCartTotal();
              this.$snotify.remove(obj.id);
            },
            bold: false,
          },
          {
            text: "否",
            action: (obj) => {
              this.$snotify.remove(obj.id);
            },
            bold: false,
          },
        ],
      });
    },
    calculateCartTotal() {
      this.$store.dispatch("product/calculateCartTotal", this.cartList);
    },
    calculateCartDirectTotal() {
      return this.cartDetailList.reduce(
        (obj, next, i) =>
          obj + Number(next.direct_price) * this.cartList[i].qty,
        0
      );
    },
  },
  mounted() {
    this.calculateCartTotal();
  },
};
</script>
<style scoped>
@import "~@/assets/css/buy-car-1.css";
</style>
