<template>
  <div class="nr-login">
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <div class="r-content">
          <h2>會員登入</h2>
          <div class="list">
            <div class="bar">
              <span>會員帳號</span>
              <input
                type="text"
                v-model="data.username"
                placeholder="輸入您註冊時輸入的行動電話"
              />
            </div>
            <div class="bar">
              <span>會員密碼</span>
              <input
                type="password"
                v-model="data.password"
                placeholder="輸入您的密碼"
              />
            </div>
            <input
              type="submit"
              v-on:click="vodSend"
              class="sbm"
              value="登  入"
            />
            <router-link
              to="/reg"
              class="sbm"
              style="text-align: center; background: #882F80"
              >註冊成為會員</router-link
            >
            <router-link
              to="/forget"
              class="sbm"
              style="text-align: center; background: #882F80"
              >忘記密碼</router-link
            >
          </div>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
  },
  data() {
    return {
      data: {
        username: "", //0911111111
        password: "", //123456
        device:sessionStorage.referrer
      },
    };
  },
  methods: {
    async login() {
      await this.$store.dispatch("member/login", this.data);
      this.$parent.getNoReadMsg()
    },
    vodSend() {
      let objMapChk = { username: "帳號", password: "密碼" };
      for (var a in this.data) {
        if (this.data[a] == "" && a !== 'device') {
          this.$snotify.error(objMapChk[a] + "未填!");
          return;
        }
      }

      this.login();
    },
  },
  mounted() {
    // if (this.$root.bolLogin) {
    //   router.push("/");
    //   return;
    // }
  },
};
</script>
<style scoped>
@import "~@/assets/css/login.css";
</style>
