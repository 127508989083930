import request from "@/plugins/request";

const baseUrl = "bonus";
function bonusPickup(data) {
  return request({
    url: baseUrl + "/bonus-pickup",
    method: "POST",
    data
  });
}


export const bonusApi = {
  bonusPickup,
};
