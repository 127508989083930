import router from "@/router";
import Vue from "vue";
import store from "@/store";

router.beforeEach(async (to, from, next) => {
  // 1=簡易 ０＝完整
  const webMode = store.getters.webMode;
  const { token } = sessionStorage;
  if (webMode) {
    switch (to.name) {
      case "login":
      case "reg":
        token ? next("/") : next();
        break;
      case "home":
      case "dbuyList":
      case "dbuydet":
      case "msg":
      case "memberInfo":
      case "memberAuth":
      case "recbuydri":
      case "cart":
      case "cartConfirm":
      case "cartFinal":
        next();
        break;
      default:
        if (from.name) {
          next(false);
        } else {
          next("/");
        }
        break;
    }
  } else {
    switch (to.name) {
      case "login":
      case "reg":
        token ? next("/") : next();
        break;
      case "gamesList":
      case "cbuyList":
      case "gtrans":
        if (token) {
          next();
        } else {
          if (from.name) {
            next(false);
          } else {
            next("/");
          }
          Vue.prototype.$snotify.error("請先登入");
        }
        break;
      default:
        next();
        break;
    }
  }
});
