import Vue from "vue";
import * as $api from "@/lib/api";
import { getStorage, setStorage, removeStorage } from "@/plugins/auth";

const state = {
  cartList: [],
  cartDetailList: [],
  cartTotalList: [],
  //
  productList: [],
  typeList: [{id:0,name:''}],
  subtypeList: [],
  detail: {}
};

const getters = {
  cartList: state => {
    state.cartList;
    var cartList = [];
    if (getStorage("cartList")) {
      cartList = JSON.parse(getStorage("cartList"));
    }
    return cartList;
  },
  cartDetailList: state => {
    state.cartDetailList;
    var cartDetailList = [];
    if (getStorage("cartDetailList")) {
      cartDetailList = JSON.parse(getStorage("cartDetailList"));
    }
    return cartDetailList;
  }
};

const mutations = {
  setCartList: (state, data) => {
    setStorage("cartList", JSON.stringify(data));
    state.cartList = data;
  },
  setCartDetailList: (state, data) => {
    setStorage("cartDetailList", JSON.stringify(data));
    state.cartDetailList = data;
  },
  //
  setProduct: (state, data) => {
    state.productList = data;
  },
  setType: (state, data) => {
    state.typeList = data;
  },
  setsubtype: (state, data) => {
    state.subtypeList = data;
  },
  setDetail: (state, data) => {
    state.detail = data;
  }
};

const actions = {
  addCartList: ({ getters, commit }, data) => {
    let cartList = Object.assign([], getters.cartList);
    let cartDetailList = Object.assign([], getters.cartDetailList);
    cartList.push(data.item);
    cartDetailList.push(data.detail);
    commit("setCartList", cartList);
    commit("setCartDetailList", cartDetailList);
    Vue.prototype.$snotify.success("已加入購物車");
  },
  removeCartList: ({ getters, commit }, key) => {
    let cartList = Object.assign([], getters.cartList);
    let cartDetailList = Object.assign([], getters.cartDetailList);
    cartList.splice(key, 1);
    cartDetailList.splice(key, 1);
    commit("setCartList", cartList);
    commit("setCartDetailList", cartDetailList);
  },
  removeaAllCartList: ({ state, commit }, _d) => {
    removeStorage("cartList");
    removeStorage("cartDetailList");
    state.cartList = [];
    state.cartDetailList = [];
  },
  calculateCartTotal({ state, commit }, cartList) {
    let cartTotalList = [];
    let cartTotalObj = {};
    cartList.forEach(x => {
      if (cartTotalObj[x.coin1]) {
        cartTotalObj[x.coin1] = cartTotalObj[x.coin1] + x.value1 * x.qty;
      } else {
        cartTotalObj[x.coin1] = x.value1 * x.qty;
      }
      if (cartTotalObj[x.coin2]) {
        cartTotalObj[x.coin2] = cartTotalObj[x.coin2] + x.value2 * x.qty;
      } else {
        cartTotalObj[x.coin2] = x.value2 * x.qty;
      }
    });

    for (let i in cartTotalObj) {
      cartTotalList.push({
        coin: i,
        value: cartTotalObj[i]
      });
    }
    state.cartTotalList = cartTotalList;
    // state.cartTotalList.splice(0,state.cartTotalList.length,...cartTotalList)
  },
  //
  async getProduct({ commit }, _d) {
    let data = await $api.productApi.getProduct(_d);
    commit("setProduct", data.data.list);
    await commit(
      "app/setPageData",
      {
        allnums: data.data.allnums,
        page: data.data.page,
        pagesize: data.data.pagesize
      },
      { root: true }
    );
  },
  async getType({ commit }, _d) {
    let data = await $api.productApi.getType(_d);
    commit("setType", data.data);
  },
  async getsubtype({ commit }, _d) {
    if (_d.maintype) {
      let data = await $api.productApi.getType(_d);
      commit("setsubtype", data.data);
    } else {
      commit("setsubtype", []);
    }
  },
  async getDetail({ commit }, _d) {
    let data = await $api.productApi.getDetail(_d);
    commit("setDetail", data.data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
