<template>
  <div class="nr-gtrans">
    <div class="center">
      <div class="tit">
        <h2>{{ this.menu | transMenuName(this.menuType) }}</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <div v-if="stickersSettings.name" class="top-nr">
          <div class="limg">
            <img :src="$baseImgUrl + stickersSettings.image" alt="">
          </div>
          <div class="r-nr">
            <div class="top-txt">
              <h2>{{ stickersSettings.name }}</h2>
              <p class="yel">
                商品編號：{{ stickersSettings.number }}
              </p>
              <span v-html="stickersSettings.remarks" />
            </div>
            <div class="btm-txt">
              <div class="t1">
                <p>
                  商品直購價：${{ stickersSettings.correct_quantity | cleanDotFilter
                  }}{{ stickersSettings.correct_currency | coinName }} /張
                </p>
                <p>
                  贈 送：{{ stickersSettings.gift_currency | coinName
                  }}{{ stickersSettings.gift_quantity | cleanDotFilter }}個
                </p>
              </div>
              <div class="t2">
                <input
                  v-model="data.quantity"
                  type="number"
                  min="1"
                  value="1"
                >張
              </div>
              <div class="t3">
                合計
                <strong style="display: unset">
                  {{ data.quantity * stickersSettings.correct_quantity }}
                </strong>
                {{ stickersSettings.correct_currency | coinName }}
              </div>
              <div class="t1">
                <p class="yel">
                  目前 {{ stickersSettings.correct_currency | coinName }} :${{
                    userInfo.D | toThousandFilter
                  }}
                </p>
              </div>
              <a class="jr" style="cursor: pointer" @click="vodSend">
                <img src="@/assets/img/jr-icon.png" alt="">立即購買
              </a>
            </div>
          </div>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    CompMarquee: require('@/components/parts/Marquee').default,
    CompFoot: require('@/components/parts/Foot').default
  },
  computed: {
    ...mapGetters({
      loginState: 'loginState',
      webMode: 'webMode'
    }),
    ...mapState({
      userInfo: state => state.member.userInfo,
      stickersSettings: state => state.stickers.stickersSettings,
      menu: state => state.website.menu
    })
  },
  data () {
    return {
      data: {
        quantity: 1
      },
      menuType: 'gtrans'
    }
  },
  mounted () {
    if (!this.loginState || this.webMode) {
      this.$router.push({ name: 'home' })
    }
    this.getStickersSettings()
  },
  methods: {
    async getStickersSettings () {
      await this.$store.dispatch('stickers/getStickersSettings')
    },
    async checkoutSticker () {
      this.data.number = this.stickersSettings.number
      const state = await this.$store.dispatch(
        'stickers/checkoutSticker',
        this.data
      )
      if (state) {
        this.$snotify.info('購買成功')
        await this.$store.dispatch('member/getuser')
      }
    },
    async clsConfirm () {
      const data = {
        strTTL: '本商品一經售出無法退換貨！是否確定購買?',
        strCont: '',
        func: () => {
          this.checkoutSticker()
        }
      }
      await this.$store.dispatch('app/clsConfirm', data)
    },
    //
    vodSend () {
      const sendNum = this.data.quantity * this.stickersSettings.correct_quantity
      const userNum = parseFloat(this.userInfo.D)
      if (userNum < sendNum) {
        this.$root.$snotify.error('額度不足')
        this.$router.push('/cbuy/list')
        return
      }
      this.clsConfirm()
    }
  }
}
</script>
<style scoped>
@import "~@/assets/css/lucky.css";
</style>
