import * as $api from "@/lib/api";
import Vue from "vue";

const state = {
  validateApiRouteData: {}
};

const mutations = {
  setValidateApiRouteData: (state, { apicode, data }) => {
    let _d = state.validateApiRouteData;
    state.validateApiRouteData = {};
    state.validateApiRouteData = _d;
    state.validateApiRouteData[apicode] = data;
  }
};

const actions = {
  async validateApiRoute({ commit, dispatch }, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    _d.apitype = 0;
    let data = await $api.thirdApi.apiRoute(_d);
    commit("setValidateApiRouteData", { apicode: _d.apicode, data: data.data });
  },
  async apiRoute({ commit, dispatch }, _d) {
    const loading = Vue.prototype.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)"
    });
    _d = await dispatch("app/addToken", _d, { root: true });
    _d.apitype = 1;
    let data = await $api.thirdApi.apiRoute(_d);
    loading.close();
    if (data) {
      return data.data.status;
    }
  },
  async checkPhone({ commit, dispatch }, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.thirdApi.checkPhone(_d);
    if (data.status) {
      return data.status;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
