<template>
  <router-view />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      loginState: "loginState",
      webMode: "webMode"
    })
  },
  async mounted() {
    if (!this.loginState || this.webMode) {
      this.$router.push({ name: "home" });
    }
  }
};
</script>
