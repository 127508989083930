<template>
  <ul>
    <li v-for="(item, index) in lottList" :key="index">
      <router-link :to="{ name: 'draw', params: { id: item.id } }">
        <div class="img-goods">
          <img style="min-height: 333px" :src="item.img" alt="" />
        </div>
      </router-link>
      <h4>{{ item.name }}</h4>
      <div class="cz">
        <div class="l-div">
          <p>
            每抽 {{ item.pay_coin | coinName }}
            <strong>{{ item.pay_amount | toThousandFilter }}</strong
            >枚
          </p>
          <router-link :to="{ name: 'draw', params: { id: item.id } }"
            >點擊抽獎</router-link
          >
        </div>
        <div class="r-div">
          <p>開始時間 {{ item.start_date | date }}</p>
          <p>抽獎券總數 {{ item.total_qty }}</p>
          <p>未抽券總數 {{ item.qty }}</p>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "CompProductItem",
  props: {
    lottList: Array,
  },
  computed: {
    ...mapGetters({
      webMode: "webMode",
    }),
  },
};
</script>
