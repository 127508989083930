import * as $api from "@/lib/api";

const state = {
  activityInfoList: [],
  ActivityMsgNoReadCount: 0
};

const mutations = {
  setActivityInfos: (state, data) => {
    state.activityInfoList = data;
    state.ActivityMsgNoReadCount = data.filter(e => e.read_status === 0).length;
  }
};

const actions = {
  async getActivityInfos({ commit }, { _d, routeName }) {
    let data = await $api.newsApi.getActivityInfos(_d);
    commit("setActivityInfos", data.data.list);
    if (routeName === "msg") {
      await commit(
        "app/setPageData",
        {
          allnums: data.data.allnums,
          page: data.data.page,
          pagesize: data.data.pagesize
        },
        { root: true }
      );
    }
    return data;
  },
  async readActivityMsg({ commit }, _d) {
    let data = await $api.newsApi.readActivityMsg(_d);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
