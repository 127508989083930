import * as $api from "@/lib/api";

const state = {
  paymentMethodList: []
};

const mutations = {
  setPaymentMethodList: (state, data) => {
    state.paymentMethodList = data;
  }
};

const actions = {
  async getPaymentMethodList({ commit ,dispatch}, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.otherApi.getPaymentMethodList(_d);
    commit("setPaymentMethodList", data.data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
