<template>
  <div class="nr-mem-auth">
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <CompMenNavmem :keyy="$route.name" />
        <!-- <div class="r-content" v-if="validateApiRouteData.sms"> -->
        <div class="r-content">
          <h2>手機認證</h2>
          <div class="bg">
            <div class="l-ct" style="width: 650px" v-if="userInfo.sms">
              <h3>
                你的手機號碼：<input
                  disabled
                  style="
                    margin-left: 12px;
                    width: 345px;
                    line-height: 36px;
                    background: #6c6a6a;
                    text-align: center;
                    border: none;
                    color: #fff;
                  "
                  type="text"
                  :value="userInfo.username"
                />
              </h3>

              <p class="color2">您的手機號碼已通過驗證</p>
            </div>
            <div class="l-ct" v-else>
              <h3 v-if="validateApiRouteData.sms">
                已發送
                {{ validateApiRouteData.sms.allnums }}
                次驗證碼至你的手機號碼：{{ userInfo.username }}
              </h3>
              <h3 v-if="validateApiRouteData.sms">
                剩餘次數:
                {{ seoSettings.sms_qty - validateApiRouteData.sms.allnums }}
              </h3>
              <P class="color1">手機請勿設定拒收陌生簡訊,不然收不到認證碼</P>
              <label>
                簡訊驗證碼
                <input
                  type="text"
                  v-model="checkPhoneData.mobilecode"
                  placeholder="請於 30 分鐘內在此輸入簡訊碼"
                />
              </label>
              <p class="color2" v-if="sendSMS">
                認證簡訊已發送，請查收!!
              </p>
              <p class="color2" v-else>尚未送出驗証碼，完成認證即贈送獎勵金!!</p>
              <div class="btn-ct">
                <a
                  style="cursor: pointer"
                  class="btn1"
                  v-on:click="checkPhone()"
                  >送出</a
                >
                <a
                  style="cursor: pointer"
                  class="btn2"
                  v-on:click="apiRoute('sms')"
                  >發送簡訊</a
                >
              </div>
            </div>
            <div class="tips" v-html="smsNotice"></div>
          </div>
        </div>
        <div class="r-content" style="margin-left: 209px; margin-top: 10px">
          <h2>電子郵件認證</h2>
          <div class="bg">
            <div class="l-ct" style="width: 650px" v-if="userInfo.mail">
              <h3>
                你的電子郵件：<input
                  disabled
                  style="
                    margin-left: 12px;
                    width: 345px;
                    line-height: 36px;
                    background: #6c6a6a;
                    text-align: center;
                    border: none;
                    color: #fff;
                  "
                  type="text"
                  :value="userInfo.email"
                />
              </h3>

              <p class="color2">您的電子郵件已通過驗證</p>
            </div>
            <div class="l-ct" style="width: 650px" v-else>
              <h3>
                你的電子郵件：<input
                  disabled
                  style="
                    margin-left: 12px;
                    width: 345px;
                    line-height: 36px;
                    background: #6c6a6a;
                    text-align: center;
                    border: none;
                    color: #fff;
                  "
                  type="text"
                  :value="userInfo.email"
                />
              </h3>
              <p class="color2" v-if="sendMail">
                認證郵件已寄出，請至信箱查收!!
              </p>
              <p class="color2" v-else>
                尚未送出驗証碼，完成認證即贈送獎勵金!!
              </p>
              <div class="btn-ct">
                <a
                  style="cursor: pointer"
                  class="btn1"
                  v-on:click="apiRoute('mailbox')"
                  >送出認證電子郵件</a
                >
              </div>
            </div>
            <div class="r-img"></div>
            <div class="tips" v-html="emailNotice"></div>
          </div>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompMenNavmem: require("@/components/parts/Navmem").default,
  },
  computed: {
    ...mapState({
      seoSettings: (state) => state.website.seoSettings,
      userInfo: (state) => state.member.userInfo,
      smsNotice: (state) => state.precautions.smsNotice,
      emailNotice: (state) => state.precautions.emailNotice,
      validateApiRouteData: (state) => state.third.validateApiRouteData,
    }),
  },
  data() {
    return {
      apicode: ["sms", "mail"],
      checkPhoneData: {
        mobilecode: "",
      },
      sendMail: false,
      sendSMS: false,
    };
  },
  methods: {
    async getSmsNoticeList() {
      await this.$store.dispatch("precautions/getSmsNoticeList");
    },
    async getEmailNoticeList() {
      await this.$store.dispatch("precautions/getEmailNoticeList");
    },
    async handleValidateApiRoute() {
      const _this = this;
      this.apicode.forEach(async (x) => {
        // userInfo 若沒註冊才請求
        if (!_this.userInfo[x]) {
          await this.validateApiRoute(x);
        }
      });
    },
    async validateApiRoute(apicode) {
      let data = {
        apicode,
      };
      await this.$store.dispatch("third/validateApiRoute", data);
    },
    async apiRoute(apicode) {
      let data = {
        apicode,
      };
      const state = await this.$store.dispatch("third/apiRoute", data);
      if (state) {
        if (apicode === "mailbox") {
          this.sendMail = true;
          this.$snotify.success("認證郵件已寄出", {
            timeout: 5000,
          });
        }
        if (apicode === "sms") {
          this.sendSMS = true;
          this.$snotify.success("認證簡訊已發送", {
            timeout: 5000,
          });
        }
        this.handleValidateApiRoute();
      }
      //
    },
    async checkPhone() {
      if (this.checkPhoneData.mobilecode == "") {
        this.$snotify.error("未填驗證碼");
        return;
      }
      const state = await this.$store.dispatch(
        "third/checkPhone",
        this.checkPhoneData
      );
      if (state) {
        this.$store.dispatch("member/getuser");
        this.handleValidateApiRoute();
      }
    },
  },
  async created() {
    await this.handleValidateApiRoute();
    this.getSmsNoticeList();
    this.getEmailNoticeList();
  },
};
</script>
<style scoped>
@import "~@/assets/css/member-rzzx.css";
</style>
