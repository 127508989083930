<template>
  <div>
    <div style="min-height: 500px; max-height: 1000px; overflow-y: auto">
      <table>
        <thead>
          <tr>
            <th>商品名稱</th>
            <th>價格</th>
            <th>數量</th>
            <th>小計</th>
            <th v-show="showDel">刪除</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in cartList" :key="index">
            <!-- 商品圖片、商品名稱 -->
            <td>
              <img
                :src="cartDetailList[index].img"
                alt=""
                style="height: 88px"
              />
              <span>{{ cartDetailList[index].name }}</span>
            </td>
            <!-- 價格 -->
            <!-- 完整模式 -->
            <td v-if="!strBType">
              <template v-if="item.coin1 !== 'T'">
                <p>
                  {{ item.coin1 | coinName }}：${{
                    item.value1 | toThousandFilter
                  }}
                </p>
                <p>
                  {{ item.coin2 | coinName }}：${{
                    item.value2 | toThousandFilter
                  }}
                </p>
              </template>
              <template v-else>
                <p>${{ item.value1 | toThousandFilter }}</p>
              </template>
            </td>
            <!-- 簡易模式 -->
            <td v-else>
              ${{ cartDetailList[index].direct_price | toThousandFilter }}
            </td>
            <!-- 價格 -->
            <td>
              {{ item.qty }}
            </td>
            <!-- 小計 -->
            <td v-if="!strBType">
              <template v-if="item.coin1 !== 'T'">
                <p>
                  {{ item.coin1 | coinName }}：${{
                    (item.value1 * item.qty) | toThousandFilter
                  }}
                </p>
                <p>
                  {{ item.coin2 | coinName }}：${{
                    (item.value2 * item.qty) | toThousandFilter
                  }}
                </p>
              </template>
              <template v-else>
                <p>${{ (item.value1 * item.qty) | toThousandFilter }}</p>
              </template>
            </td>
            <td v-else>
              ${{
                (cartDetailList[index].direct_price * item.qty)
                  | toThousandFilter
              }}
            </td>
            <td v-show="showDel">
              <i
                class="del"
                v-on:click="vodDelete(cartDetailList[index], index)"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pri">
      <div class="l-jg">應繳金額</div>
      <div class="r-jg" v-if="!strBType">
        <template v-if="cartTotalList.length > 0">
          <p v-for="(item, key) in cartTotalList" :key="key">
            <span v-if="item.coin !== 'T'">
              {{ item.coin | coinName }}：${{ item.value | toThousandFilter }}
            </span>
            <span v-else> ${{ item.value | toThousandFilter }} </span>
          </p>
        </template>
        <p v-else>$ 0</p>
      </div>
      <div class="r-jg" v-else>
        <template v-if="cartTotalList.length > 0">
          <p>${{ calculateCartDirectTotal() | toThousandFilter }}</p>
        </template>
        <p v-else>$ 0</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CartTable",
  props: {
    cartDetailList: Array,
    cartList: Array,
    cartTotalList: Array,
    strBType: Number,
    showDel: Boolean,
  },
  methods: {
    vodDelete(obj, key) {
      this.$snotify.confirm("是否確定刪除 " + obj.name, null, {
        buttons: [
          {
            text: "是",
            action: (obj) => {
              this.$store.dispatch("product/removeCartList", key);
              this.cartList.splice(key, 1);
              this.calculateCartTotal();
              this.$snotify.remove(obj.id);
            },
            bold: false,
          },
          {
            text: "否",
            action: (obj) => {
              this.$snotify.remove(obj.id);
            },
            bold: false,
          },
        ],
      });
    },
    calculateCartTotal() {
      this.$store.dispatch("product/calculateCartTotal", this.cartList);
      // this.$store.dispatch("product/calculateCartTotal", JSON.parse(localStorage.cartList));
    },
    calculateCartDirectTotal() {
      return this.cartDetailList.reduce(
        (obj, next, i) =>
          obj + Number(next.direct_price) * this.cartList[i].qty,
        0
      );
    },
  },
  mounted() {
    this.calculateCartTotal();
  },
};
</script>
