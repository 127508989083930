<template>
  <div class="nr-mem-take">
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <CompMenNavmem :keyy="'memberRecbuysti'" />
        <div class="r-content">
          <h2>領取希望貼紙</h2>
          <div class="tab">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th class="th3">內容</th>
                  <th>可領取數量</th>
                  <th>領取數量</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td class="th3">希望貼紙</td>
                  <td>{{ userInfo.stickers }}</td>
                  <td>{{ userInfo.stickers }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colspan="4"
                    style="
                      padding: 10px 10px;
                      line-height: 20px;
                      background: #882f80;
                      font-size: 17px;
                      font-weight: 500;
                    "
                  >
                    最低領取數量300張
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <ReceiveInfoList :data="data" />
          <div class="btn-ct">
            <input
              type="submit"
              value="完成確認"
              v-on:click="vodSend()"
              id="iptBtnSend"
            />
          </div>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompMenNavmem: require("@/components/parts/Navmem").default,
    ReceiveInfoList: require("@/components/ReceiveInfoList").default,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.member.userInfo,
    }),
  },
  data() {
    let initData = {
      quantity: 0,
      receive_name: "",
      receive_phone: "",
      receive_email: "",
      receive_address: "",
      receive_time: "不指定時間",
      remarks: "",
    };
    return {
      initData,
      data: Object.assign({}, initData),
    };
  },
  methods: {
    vodSend() {
      this.data.quantity = this.userInfo.stickers;
      let objChk = {
        receive_name: { strMsg: "姓名未填" },
        receive_phone: { strMsg: "電話未填" },
        receive_address: { strMsg: "地址未填" },
      };
      for (let k in this.data) {
        if (objChk[k]) {
          if (this.data[k] == "") {
            this.$snotify.error(objChk[k]["strMsg"]);
            return;
          }
        }
      }
      if (this.data.quantity < 300) {
        this.$snotify.error("低於最低領取數量");
        return;
      }
      this.send();
    },
    async send() {
      const loading = this.$loadingCase("領取中")
      const state = await this.$store.dispatch(
        "stickers/pickupSticker",
        this.data
      );
      if (state) {
        this.$snotify.info("訂單送出成功");
        this.$router.push({ name: "memberRecbuysti" });
      }
      loading.close();
    },
  },
  mounted() {},
};
</script>
<style scoped>
@import "~@/assets/css/member-lq.css";
</style>
