<template>
  <div class="nr-lott">
    <div class="center">
      <div class="tit">
        <h2>{{ this.menu | transMenuName(this.menuType) }}</h2>
      </div>
      <CompMarquee />
      <div class="list">
        <div class="fx">
          <select v-model="intCoinType" @change="getLottList">
            <option style="color: black" value="">
              全部幣別
            </option>
            <option style="color: black" value="D">
              希望幣
            </option>
            <option style="color: black" value="G">
              金幣
            </option>
            <option style="color: black" value="S">
              銀幣
            </option>
            <option style="color: black" value="C">
              銅幣
            </option>
          </select>
          <div class="title-list">
            <router-link
              :to="{ name: 'lottList', params: { id: 0 } }"
              :class="{ fst: true, on: mainTypeId == '' }"
              @click="mainTypeId = ''"
            >
              全部
            </router-link>
            <router-link
              v-for="(item, index) in typeList"
              :key="index"
              :to="{ name: 'lottList', params: { id: item.id } }"
              :class="{
                brn: index == typeList.length - 1,
                on: item.id == mainTypeId,
              }"
            >
              {{ item.name }}
            </router-link>
            <!-- <a href="" @click="mainTypeId = 0" :class="{ on: mainTypeId == '0' }">其他</a> -->
          </div>
        </div>
        <div class="search">
          <div style="font-size: 18px; font-weight: bold">
            次分類:
            <select
              v-model="subtypeId"
              style="font-size: 16px; font-weight: bold; color: black"
            >
              <option value="" style="color: black">
                全部
              </option>
              <option
                v-for="(item, index) in subtypeList"
                :key="index"
                :value="item.id"
                style="color: black"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div>
            <input
              v-model.trim="searchName"
              type="text"
              placeholder="搜尋產品"
            >
            <span class="btn btn-warning" @click="search()">搜尋</span>
          </div>
        </div>
        <CompLottItem :lott-list="lottList" />
        <CompPager
          ref="pager"
          :int-per-page="page.pagesize"
          :int-tol-cnt="pageData.allnums"
          :int-cur-page="page.currentPage"
          @evtpagerchg="vodGetPage"
        />
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    CompMarquee: require('@/components/parts/Marquee').default,
    CompFoot: require('@/components/parts/Foot').default,
    CompPager: require('@/components/parts/pager').default,
    CompLottItem: require('@/components/LottItem').default
  },
  computed: {
    ...mapState({
      typeList: state => state.product.typeList,
      pageData: state => state.app.pageData,
      subtypeList: state => state.product.subtypeList,
      lottList: state => state.activity.lottList,
      menu: state => state.website.menu
    }),
    ...mapGetters({
      loginState: 'loginState',
      webMode: 'webMode'
    })
  },
  data () {
    const initPage = { pagesize: 15, currentPage: 1 }
    return {
      initPage,
      page: Object.assign({}, initPage),
      mainTypeId: '',
      searchName: '',
      subtypeId: '',
      intCoinType: '',
      menuType: 'lott'
    }
  },
  watch: {
    async mainTypeId (id) {
      this.page = { pagesize: 15, currentPage: 1 }
      this.subtypeId = ''
      this.searchName = ''
      this.getLottList()
      this.getsubtype(id)
    },
    async subtypeId (id) {
      this.page = { pagesize: 15, currentPage: 1 }
      this.searchName = ''
      this.getLottList()
    },
    async $route (to, from) {
      await this.getType()
      await this.getLottList()
    }

  },
  async mounted () {
    await this.getType()
    await this.getLottList()
  },
  methods: {
    vodGetPage (page) {
      this.page.currentPage = page
      this.getLottList()
    },
    search () {
      if (this.searchName == '') {
        this.$snotify.error('請輸入搜尋關鍵字')
        return
      }
      this.page = { pagesize: 15, currentPage: 1 }
      this.subtypeId = ''
      this.getLottList()
    },
    async getLottList () {
      const data = {
        page: this.page.currentPage,
        pagesize: this.page.pagesize,
        maintype: this.mainTypeId,
        subtype: this.subtypeId || undefined,
        name: this.searchName,
        coin: this.intCoinType
      }
      await this.$store.dispatch('activity/getLottList', data)
    },
    async getType () {
      await this.$store.dispatch('product/getType')
      this.initScrollbar()
    },
    async getsubtype (maintype) {
      const data = {
        maintype
      }
      await this.$store.dispatch('product/getsubtype', data)
    },
    initScrollbar () {
      this.$nextTick(() => {
        $('.fx .title-list').mCustomScrollbar({
          axis: 'x',
          scrollbarPosition: 'outside'
        })
      })
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id) {
      this.mainTypeId = Number(to.params.id)
    } else {
      this.mainTypeId = ''
    }
  }
}
</script>
<style scoped>
@import "~@/assets/css/lucky-draw.css";
</style>
