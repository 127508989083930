import Vue from "vue";
import * as $api from "@/lib/api";

const state = {

};

const getters = {};

const mutations = {
 
};

const actions = {
  async bonusPickup({ commit }, _d) {
    let data = await $api.bonusApi.bonusPickup(_d);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
