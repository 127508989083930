import request from "@/plugins/request";

const baseUrl = "";
function getPaymentMethodList(data) {
  return request({
    url: "/payment/payment-methods",
    method: "POST",
    data
  });
}

export const otherApi = {
  getPaymentMethodList
};
