<template>
  <div
    class="bg-cover"
    @click="onClose"
  >
    <img
      class="img"
      src="@/assets/img/payment_callback_pic.jpg"
      alt=""
    >
    <iframe hidden src="/pay-cb.html" />
  </div>
</template>
<script>
export default {
  mounted () {
  },
  methods: {
    onClose () {
      this.$store.commit('recharge/setPaymentCbVisible', false)
    }
  }
}
</script>

<style scoped>
.bg-cover {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
}
.bg-cover > img {
  cursor: pointer;
}
</style>
