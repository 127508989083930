<template>
  <div class="nr-mem-recbuydri">
    <div
      style="display: none; padding: 0px"
      id="recbuydri-content"
      data-padding="0"
    >
      <div
        class="nr-mem-recbuydri"
        style="width: 800px; height: 600px; background: unset"
      >
        <div class="center" style="width: unset">
          <div class="ctnr" style="padding: unset">
            <div
              class="r-content"
              style="width: unset; float: unset; margin: unset"
            >
              <table>
                <thead>
                  <tr>
                    <th width="50%">商品名稱</th>
                    <th width="33%">價格</th>
                    <th width="17%">數量</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in aryDet" :key="index">
                    <td>
                      <img style="float: left" :src="item.img" alt="" />
                      <span style="float: left">{{ item.name }}</span>
                    </td>
                    <td>
                      <span> {{ item.price }} </span>
                    </td>
                    <td>
                      {{ item.qty }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <CompMenNavmem :keyy="'memberRecbuydri'" />
        <div class="r-content">
          <h2>直購商品記錄</h2>
          <table>
            <thead>
              <tr>
                <th>訂單編號</th>
                <th>訂單日期</th>
                <th>訂單金額</th>
                <th>出貨狀況</th>
                <th>出貨日期</th>
                <th>快遞/單號</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in recordList"
                :key="index"
                v-on:click="vodDetail(item.items)"
                style="cursor: pointer"
              >
                <td style="text-align: center">{{ item.single_number }}</td>
                <td>{{ item.under_at | date }}</td>
                <!-- 訂單金額 -->
                <td v-if="item.amount.indexOf('新台幣') !== -1">
                  {{ item.amount | cleanDotFilter }}
                </td>
                <td v-else>
                  <div>
                    {{ item.amount.substr(0, item.amount.indexOf(",")) }}
                  </div>
                  <div>
                    {{ item.amount.substr(item.amount.indexOf(",") + 1) }}
                  </div>
                </td>
                <!-- 訂單狀態 -->
                <!-- 訂單狀態/出貨狀況 -->
                <td v-text="item.goods_status"></td>
                <!-- 訂單狀態/出貨狀況 -->
                <!-- 出貨日期 -->
                <td>{{ item.shipment_at }}</td>
                <!-- 出貨日期 -->
                <!-- 快遞編號 -->
                <td style="line-height: 30px">
                  {{ item.logistics_name }}<br />{{ item.logistics_number }}
                </td>
                <!-- 快遞編號 -->
              </tr>
            </tbody>
          </table>
          <CompPager
            @evtpagerchg="vodGetPage"
            :int-per-page="page.pagesize"
            :int-tol-cnt="pageData.allnums"
            :int-cur-page="page.currentPage"
            ref="pager"
          ></CompPager>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompPager: require("@/components/parts/pager").default,
    CompMenNavmem: require("@/components/parts/Navmem").default,
  },
  computed: {
    ...mapState({
      pageData: (state) => state.app.pageData,
      recordList: (state) => state.directpurchase.recordList,
    }),
  },
  data() {
    return {
      page: {
        pagesize: 10,
        currentPage: 1,
      },
      //
      aryDet: [],
      aryList: [],
      intTolCnt: 0,
      intPerPage: 10,
      intCurPage: 1,
      processStatus: {
        0: "未付款",
        1: "未出貨",
        2: "已出貨",
        3: "退貨中",
        4: "換貨中",
        5: "已送達",
        6: "完成交易",
        7: "取消交易",
      },
    };
  },
  methods: {
    vodGetPage(page) {
      this.page.currentPage = page;
      this.getRecord();
    },
    async getRecord() {
      const data = {
        page: this.page.currentPage,
        pagesize: this.page.pagesize,
        token: sessionStorage.token,
      };
      await this.$store.dispatch("directpurchase/record", data);
    },
    //
    vodDetail(items) {
      this.aryDet = items;
      $.fancybox.open({
        src: "#recbuydri-content",
        type: "inline",
        opts: {},
      });
    },
  },

  async mounted() {
    await this.getRecord();
  },
};
</script>
<style scoped>
@import "~@/assets/css/member-spjl.css";
</style>
