import * as $api from "@/lib/api";

const state = {
  recordList: [],
};

const mutations = {
  RECORD: (state, data) => {
    state.recordList = data;
  }
};

const actions = {
  async checkoutDirectpurchase({ commit, dispatch }, _d) {
    _d = await dispatch("app/addToken", _d, { root: true });
    let data = await $api.directpurchaseApi.checkoutDirectpurchase(_d);

    if (data.status) {
      await dispatch("product/removeaAllCartList", {}, { root: true });
    }
    return data;
  },
  async record({ commit }, _d) {
    let data = await $api.directpurchaseApi.record(_d);
    commit("RECORD", data.data.list);

    await commit(
      "app/setPageData",
      {
        allnums: data.data.allnums,
        page: data.data.page,
        pagesize: data.data.pagesize
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
