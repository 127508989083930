import * as $api from "@/lib/api";

const state = {
  rechargeNoticeList: {},
  checkoutNotice: {},
  activityRuleList: {},
  smsNotice:
    "<h4>注意事項：</h4><p>1.一個手機號碼只可以認證三次。</p><p>2.提醒請勿使用他人手機做為會員手機認證。</p><p>3.進行手機認證是不收任何費用，會員也不會有多餘的電信費用。</p>",
  emailNotice: "<h4>注意事項：</h4><p>1.如長時間未收到,請查看您的垃圾郵件收件夾。</p>",
  shoppingRules:''
};

const mutations = {
  setRechargeNoticeList: (state, data) => {
    state.rechargeNoticeList = data;
  },
  setSmsNoticeList: (state, data) => {
    state.smsNotice = data;
  },
  setEmailNoticeList: (state, data) => {
    state.emailNotice = data;
  },
  setCheckoutNoticeList: (state, data) => {
    state.checkoutNotice = data;
  },
  setActivityRuleList: (state, data) => {
    state.activityRuleList = data;
  },
  SETSHOPPINGRULES: (state, data) => {
    state.shoppingRules = data;
  }
};

const actions = {
  async getRechargeNoticeList({ commit }, _d) {
    let data = await $api.precautionsApi.getRechargeNoticeList(_d);
    commit("setRechargeNoticeList", data.data[0]);
  },
  async getSmsNoticeList({ commit }, _d) {
    let data = await $api.precautionsApi.getSmsNoticeList(_d);
    commit("setSmsNoticeList", data.data[0].content);
  },
  async getEmailNoticeList({ commit }, _d) {
    let data = await $api.precautionsApi.getEmailNoticeList(_d);
    commit("setEmailNoticeList", data.data[0].content);
  },
  async getCheckoutNoticeList({ commit }, _d) {
    let data = await $api.precautionsApi.getCheckoutNoticeList(_d);
    commit("setCheckoutNoticeList", data.data[0]);
  },
  async getActivityRuleList({ commit }, _d) {
    let data = await $api.precautionsApi.getActivityRuleList(_d);
    commit("setActivityRuleList", data.data[0]);
  },
  async getShoppingRules({ commit }, _d) {
    let data = await $api.precautionsApi.getShoppingRules(_d);
    commit("SETSHOPPINGRULES", data.data[0].content);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
