<template>
  <div class="nr">
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <div class="r-content">
          <h2>忘記密碼</h2>
          <div class="list">
            <h5>所有標有星號（<span>*</span>）的欄位皆為必填</h5>
            <div class="bar">
              <span>會員帳號<i>*</i></span>
              <input
                v-model="data.username"
                type="number"
                placeholder="請填寫正確，系統稍後會寄發新密碼至信箱。範例：0933123456"
              />
            </div>
          </div>
          <input
            v-on:click="vodSend()"
            type="button"
            class="sbm"
            value="送  出"
          />
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    CompMarquee: require('@/components/parts/Marquee').default,
    CompFoot: require('@/components/parts/Foot').default,
  },
  computed: {
    ...mapGetters({
      loginState: 'loginState',
    }),
  },
  data() {
    return {
      objShare: {
        strCode: '',
      },
      data: {
        username: '',
      },
      bolChkRule: false,
      ruleFile: '',
    };
  },
  methods: {
    async forget() {
      const state = await this.$store.dispatch('member/forget', this.data);
    },
    vodSend() {
      let objChk = {
        username: '會員帳號',
      };
      for (var a in this.data) {
        if (this.data[a] == '') {
          this.$snotify.error('請填寫 ' + objChk[a]);
          return;
        }
      }
      this.forget();
    },
  },
};
</script>
<style scoped>
@import "~@/assets/css/enroll.css?1.0.2";
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
