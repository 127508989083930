<template>
  <div class="nr-msg">
    <div class="center">
      <div class="tit">
        <h2>APP下載</h2>
      </div>
      <CompMarquee />
      <div class="list" style="min-height: 700px">
        <h2 class="download__title">APP下載</h2>
        <el-row class="download__row">
          <el-col :span="8" v-for="(item, index) in downloadList" :key="index">
            <a :href="item.url" class="download__name" target="_blank">
              <span class="download__button">{{ item.name }}</span>
            </a>
            <img :src="item.image" class="download__img" alt="" />
          </el-col>
        </el-row>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
  },
  computed: {
    ...mapState({
      downloadList: (state) => state.appsetting.downloadList,
    }),
  },
  data() {
    return {};
  },
  methods: {
    async getDownloadList() {
      await this.$store.dispatch("appsetting/getDownloadList");
    },
  },
  async mounted() {
    await this.getDownloadList();
  },
};
</script>
<style scoped>
@import "~@/assets/css/message.css";
.download__title {
  width: 100%;
  font-size: 28.8px;
  color: #d9d9d8;
  font-weight: 500;
  border-bottom: 2px solid #882f80;
  padding: 25px 0 13px 0;
}
.download__row {
  display: flex;
  justify-content: space-evenly;
  margin-top: 88px;
}
.download__name {
  display: block;
  text-align: center;
}
.download__button {
  display: inline-block;
  width: 250px;
  line-height: 40px;
  font-size: 20px;
  background-color: rgb(136, 47, 128);
}
.download__img {
  display: block;
  margin: 15px auto 0;
  width: 138px;
  height: 138px;
}
</style>
