<template>
  <div class="nr-draw">
    <div class="center">
      <div class="tit">
        <h2>{{ this.menu | transMenuName(this.menuType) }}</h2>
      </div>
      <CompMarquee />
      <div class="detail-con">
        <!-- 產品、說明顯示 -->
        <div class="sec01">
          <img
            :src="detail.img"
            class="luck-i"
            style="height: auto; max-height: 543px"
          >
          <div class="right-content">
            <h2>{{ detail.name }}</h2>
            <h3>
              <img src="@/assets/img/cl-ic.png">開始時間：{{
                detail.start_date | date
              }}
              ～ {{ detail.end_date | date }}
            </h3>
            <h4>總抽獎券: <span>{{ parseNum(detail.total_qty) }}</span>張</h4>
            <div class="prog">
              <div
                class="in-prog"
                style="width: 20%"
                :style="{
                  width:
                    ((detail.total_qty - detail.qty) / detail.total_qty) * 100 +
                    '%',
                }"
              />
              <h5>已點戳 {{ parseNum(detail.total_qty - detail.qty) }} 張</h5>
            </div>
            <hr>
            <h6>
              ●
              <span>抽獎券種類：{{ detail.pay_coin | coinName }}</span>
            </h6>
            <h6>
              ●
              <span>每張抽獎券：{{ detail.pay_amount | toThousandFilter }} 點</span>
            </h6>
            <!-- <h6 v-if="app.bolLogin == true"> -->
            <h6>
              ●
              <span
                class="sp"
              >可抽獎{{ detail.pay_coin | coinName }}：{{
                userInfo[detail.pay_coin] | toThousandFilter
              }}
                點</span>
            </h6>
            <hr>
            <router-link
              class="buy-btn"
              to="/gtrans"
            >
              取得更多金幣
            </router-link>
            <!-- 獎項 趴數 -->
            <p
              v-for="(item, index) in detail.awardlist"
              :key="index"
              :class="{ po: item.award_level == 0 }"
              @click="vodChgPic(item.img)"
            >
              <strong>{{ item.lv_text }}</strong><span
                style="width: 130px; display: inline-block"
              >{{ item.probability | transPercentage }}
                <!-- {{item.intIdx!==9999?item.numQty:"" }}{{item.intIdx!==9999?"名":""}} --></span>
              {{ item.pname }}
            </p>
          </div>
        </div>
        <!-- 轉盤樣式示意圖 -->
        <ul class="sec02">
          <li v-for="(item, index) in detail.turntables" :key="index">
            <img
              width="90"
              :src="'/assets/js/game/assets/img/b' + item.unit + '.png'"
            >
            <p>{{ item.probability }}</p>
            <p>{{ item.qty }}名額</p>
          </li>
        </ul>
        <CompPager
          ref="pager"
          :int-per-page="page.pagesize"
          :int-tol-cnt="detail.total_qty"
          :int-cur-page="page.currentPage"
          @evtpagerchg="vodGetPage"
        />
        <!-- 連抽 -->
        <ul class="sec03">
          <li v-for="(item, index) in detail.member_draw_nums" :key="index">
            <p @click="vodPlayMulti(item)">
              {{ item }}連抽 >
            </p>
          </li>
        </ul>
        <!-- 單抽點選 -->
        <ul class="sec04">
          <li
            v-for="(item, index) in aryCoin"
            :key="index"
            :style="{ cursor: item.bol == true ? 'pointer' : 'not-allowed' }"
            @click="item.bol == true ? vodPlaySingle() : null"
          >
            <h6>{{ item.int }}</h6>
            <div :class="['jcon', item.bol == true ? 'gold' : 'silver']" />
          </li>
        </ul>
      </div>
      <div class="shadow mask" style="display: none">
        <div class="lds-roller">
          <div /><div /><div /><div /><div /><div /><div /><div />
        </div>
      </div>
    </div>

    <!-- PIXI -->
    <div class="cjcon mask" style="display: none">
      <div class="divPixiX">
        <div id="div_pixi" data-role="page" style="width: 853px; height: 897px;">
          <canvas id="cvs_pixi" width="853" height="897" />
        </div>
      </div>

      <!-- <p>
<ol><li>1.若未點擊"停"，則系統將於１０秒內自動停轉並開出獎項。</li><li>2.若點擊"停"，則系統於５秒內停轉並開出獎項。</li><li>3.獲獎後請至【會員中心】中的【中獎紀錄/提領】查詢並領獎。</li><li>4.要進入會員中心時，請點擊頁面右上方的會員暱稱處進入。</li></ol>        </p> -->
      <section v-html="activityRuleList.content" />
      <section style="bottom: 40px">
        活動時間：{{ detail.start_date | date }}至{{ detail.end_date | date }}
      </section>
    </div>

    <!-- 單抽開獎 -->
    <div class="win-layer win-layer1 mask" style="display: none">
      <div class="wins win01" style="display: block; top: 0%; height: 100vh">
        <p>恭喜您在本次抽獎當中獲得</p>
        <h2 class="h2Pri" style="top: 340px">
          <img
            :src="lottSingleAction.award[0].img"
            alt=""
            style="width: 283px"
          >
          <br><span class="spPri" style="font-size: 35px">{{
            lottSingleAction.award[0].lv_text
          }}</span>
        </h2>
        <a
          class="dialogBtn"
          @click="vodGetPri"
        />
      </div>
    </div>
    <!-- 連抽開特獎 -->
    <div class="win-layer win-layer2 mask" style="display: none">
      <div class="wins win01" style="display: block; top: 0%; height: 780px">
        <p>恭喜您在本次抽獎當中獲得</p>
        <h2 class="h2Pri" style="top: 340px">
          <img
            :src="lottSingleAction.award[0].img"
            alt=""
            style="width: 283px"
          >
          <br><span class="spPri" style="font-size: 35px">{{
            lottSingleAction.award[0].lv_text
          }}</span>
        </h2>
        <a
          class="dialogBtn"
          @click="vodGetPri3"
        />
      </div>
    </div>
    <!-- 連抽開獎 -->
    <div class="winss mask" style="display: none">
      <h6>恭喜您獲得以下獎品：</h6>
      <img
        v-show="lottMultiAction.award.length === 0"
        class="multiLottImg"
        src="@/assets/img/ready.gif"
        alt=""
      >
      <ul id="lott-ul" style="height: 339px; overflow: auto">
        <li v-for="(item, index) in lottMultiAction.award" :key="index">
          <div class="win-img" :style="awardStyle(item.award_level)" />
          <p>{{ item.lv_text }}</p>
        </li>
      </ul>
      <a class="win-btn" @click="vodGetPri2" />
    </div>
  </div>
  </div>
</template>
<script>
import VUE from 'vue'
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    CompMarquee: require('@/components/parts/Marquee').default,
    CompFoot: require('@/components/parts/Foot').default,
    CompPager: require('@/components/parts/pager').default
  },
  computed: {
    ...mapGetters({
      loginState: 'loginState',
      webMode: 'webMode'
    }),
    ...mapState({
      coinList: state => state.coin.coinList,
      detail: state => state.activity.detail,
      userInfo: state => state.member.userInfo,
      lottSingleAction: state => state.activity.lottSingleAction,
      // lottMultiAction: (state) => state.activity.lottMultiAction,
      menu: state => state.website.menu,
      activityRuleList: state => state.precautions.activityRuleList
    }),
    endLottFlag () {
      return this.lottMultiAction.award.length === this.flagTimes
    }
  },
  data () {
    return {
      page: { pagesize: 80, currentPage: 1 },
      aryCoin: [],
      id: this.$route.params.id,
      objPriMul: {},
      priStyle: [
        '#15FF4C',
        '#00FFFA',
        '#5C92E5',
        '#FF6AF7',
        '#0800A9',
        '#FC650C',
        '#107F00',
        '#8D00E9',
        '#FFFFFF',
        '#9FA4B1',
        '#000000'
      ],
      menuType: 'lott',
      lottMultiAction: { award: [] },
      flagTimes: 0
    }
  },
  async mounted () {
    const id = parseInt(this.$route.params.id)
    await this.getDetail(id)
    this.loadJS()
    window.top.addEventListener('evt.game.wheel.result', this.handler)
  },
  created () {
    this.getActivityRuleList()
  },
  methods: {
    parseNum (number) {
      let num = 0
      if (isNaN(number)) {
        num = 0
      } else {
        num = parseInt(number)
      }
      return num
    },
    // 取得商品資料
    async getDetail (id) {
      const status = await this.$store.dispatch('activity/getDetail', {
        actid: id
      })
      if (status) {
        this.vodPageRen()
      } else {
        this.$router.push({ name: 'lott' })
      }
    },
    loadJS () {
      const ary = [
        'assets/js/game/assets/js/conf/appConf.js',
        'assets/js/game/assets_web/js/arthur.pixi.helper.js',
        'assets/js/game/assets/js/appGame.js',
        'assets/js/game/assets/js/appMc.js'
      ]
      this.xx = () => {
        const str = ary.shift()
        requirejs([str], () => {
          if (ary.length > 0) {
            this.xx()
          } else {
          }
        })
      }
      this.xx()
    },
    vodAddCart (detail) {
      if (!this.loginState) {
        this.$snotify.error('未登入會員不能購買商品')
        return
      }
      this.data.qty = parseInt(this.data.qty)
      if (!this.data.qty > 0) {
        return
      }
      const welfareData = this.data.price_type
        ? this.welfareData[this.data.price_type]
        : ''
      const item = Object.assign(this.data, welfareData)
      this.$store.dispatch('product/addCartList', {
        item,
        detail: Object.assign({}, this.detail)
      })
    },
    vodGetPage (page) {
      this.page.currentPage = page
      this.vodPageRen()
    },
    // 單抽點選渲染
    vodPageRen () {
      this.aryCoin = []
      const intBase = this.page.pagesize * (this.page.currentPage - 1)
      const intLeftR = this.detail.qty
      Array(this.page.pagesize)
        .fill(0)
        .map((v, i) => {
          const obj = { int: intBase + i + 1, bol: true }
          if (obj.int > intLeftR) {
            obj.bol = false
          }
          if (obj.int > this.detail.total_qty) {
            return true
          }
          this.aryCoin.push(obj)
        })
    },
    // 單抽
    async vodPlaySingle () {
      $('.shadow').show()
      const state = await this.$store.dispatch('activity/action', {
        token: sessionStorage.token,
        actid: this.id,
        times: 1
      })
      if (state) {
        this.getDetail(this.id)
        this.$store.dispatch('member/getuser')
        // 不是每次都有動畫（有機率）
        if (this.lottSingleAction.turntable === '') {
          const { lv_text, award_level } = this.lottSingleAction.award[0]
          this.handler({ detail: { strPrize: lv_text, intPri: award_level } })
        } else {
          this.vodPlayAni(this.lottSingleAction)
        }
      } else {
        $('.shadow').hide()
      }
    },
    // 單抽動畫
    vodPlayAni (obj) {
      this.intIdx = obj.award.award_level

      $('.divPixiX').html('')
      $('.divPixiX').append(
        '<div data-role="page" id="div_pixi" style="width: 853px; height: 897px;"><canvas id="cvs_pixi" width="853" height="897"></canvas></div>'
      )
      ClsPIXI = new ClsPIXIOri()

      PIXI.loader.reset()

      ClsPIXI.strAstPfix = 'assets/js/game/assets/'

      objGame = new appGame()
      objGame.addEventListener(objGame.objEvt.strGameRdy, () => {
        const aryPos = ClsMisc.intRnd(0, obj.award[0].turn_keys.length - 1)
        const intPriIdx = obj.award[0].turn_keys[aryPos]
        objGame.ctGame = new mcWhl(obj.turntable.awards, intPriIdx)
        objGame.objStage.addChild(objGame.ctGame)
      })
      $('.cjcon').show()
    },
    // 連抽
    async vodPlayMulti (times) {
      // 檢查是否夠錢抽獎
      this.lottMultiAction = { award: [] }
      const intTimes = times * 1
      this.flagTimes = intTimes
      const mustPay = this.detail.pay_amount * intTimes
      const userMoney = this.userInfo[this.detail.pay_coin] * 1
      if (mustPay > userMoney) {
        this.$snotify.error('遊戲幣不足，無法進行連抽。')
        return
      }
      // 初始化狀態
      let data
      let tempDataAry = []
      if (objConf.bolSnd) {
        this.$bus.$emit('play:music', 'click_draw')
        this.$bus.$emit('play:music', 'consecutive')
      }
      $('.shadow').show()
      $('.winss').show()
      // call API
      const round = Math.ceil(intTimes / 50)
      const apiTimes = round > 1 ? 50 : intTimes

      for (let i = 0, len = round; i < round; i++) {
        data = await this.$store.dispatch('activity/actionMultiTimes', {
          token: sessionStorage.token,
          actid: this.id,
          times: apiTimes
        })
        data && this.insertMultiLoot(data)
        if (data.status) {
          tempDataAry = tempDataAry.concat(data.data.award)
        } else {
          if (tempDataAry.length === 0) {
            $('.shadow').hide()
            $('.winss').hide()
            this.$bus.$emit('pause:music', { id: 'consecutive', goZero: true })
          }
          this.flagTimes = tempDataAry.length
          break
        }
      }

      this.getDetail(this.id)
      this.$store.dispatch('member/getuser')
      const special = tempDataAry.filter(item => item.award_level === 0)
      if (special.length) {
        await this.$store.commit('activity/ACTION', {
          award: [special[0]]
        })
        setTimeout(() => {
          objConf.bolSnd &&
            this.$bus.$emit('pause:music', {
              id: 'consecutive',
              goZero: false
            })

          objConf.bolSnd && this.$bus.$emit('play:music', 'special_award')
          setTimeout(() => {
            objConf.bolSnd && this.$bus.$emit('play:music', 'consecutive')
          }, 8000)
          $('.win-layer2').show()
          $('.win01').show()
        }, 2000)
      }
    },
    vodGetPri () {
      $('.mask').hide()
    },
    vodGetPri2 () {
      this.endLottFlag && $('.mask').hide()
      this.$bus.$emit('pause:music', { id: 'consecutive', goZero: true })
    },
    vodGetPri3 () {
      $('.win-layer2').hide()
    },
    handler ({ detail: { strPrize = '普獎', intPri } }) {
      const _this = this
      setTimeout(() => {
        // 展示圖片
        // var objx = this.objData.aryPriz.filter((o) => {
        //   if (o.intIdx == this.objPriSin.objPri.intIdx) {
        //     $(".h2Pri img").attr("src", objSvr.BaseUrlFile + o.str_img);
        //     return o;
        //   }
        // })[0];
        // this.$root.objMem.objBase = this.objPriSin.objBal;

        $('.cjcon').hide()
        $('.win-layer1').show()
        $('.win01').show()
        switch (strPrize) {
          case '普獎':
            objConf.bolSnd && this.$bus.$emit('play:music', 'normal_award')
            break
          case '特獎':
            tobjConf.bolSnd && this.$bus.$emit('play:music', 'special_award')
            break
          default:
            break
        }
      }, 2000)
    },
    awardStyle (award) {
      return award != 9999
        ? { border: this.priStyle[award] + ' 3px solid' }
        : ''
    },
    vodChgPic (str) {
      this.detail.img = str
    },
    cleanDotFilter (string) {
      return string.substr(0, string.indexOf('.'))
    },
    // 分批塞入，scroll bar 調整到下下方
    insertMultiLoot (data) {
      let scrollHeight = $('#lott-ul')[0].scrollHeight
      data.data.award.forEach((e, i) => {
        setTimeout(() => {
          // this.$store.commit("activity/ACTIONMULTITIMES", e);
          this.lottMultiAction.award.push(e)
          if ($('#lott-ul')[0].scrollHeight > scrollHeight) {
            scrollHeight = $('#lott-ul')[0].scrollHeight
            $('#lott-ul')[0].scrollTop = scrollHeight
          }
        }, i * 300)
      })
    },
    async getActivityRuleList () {
      await this.$store.dispatch('precautions/getActivityRuleList')
    }
  }
}
</script>
<style scoped>
@import "~@/assets/css/lucky-details.css";
.multiLottImg {
  width: 540px;
  position: absolute;
  left: 95px;
  top: 350px;
  z-index: 2;
}

.lds-roller {
  display: inline-block;

  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%; right: 50%;
  transform: translate(50%,-50%);
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dialogBtn{
  position: absolute;
  cursor: pointer;
    width: 100%;
    height: 50vh;
    bottom: 0px;
}
</style>
