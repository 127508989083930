export const validate = {
  required: {
    test: val => (val ? true : false),
    msg: val => `${val}不得為空`
  },
  validateLength: {
    test: (val, { min, max }) => {
      const reg = new RegExp(`^.{${min},${max}}$`);
      return reg.test(val);
    },
    msg: ({ min, max }) => `請輸入${min}~${max}位數字或英文字母`
  }
};
