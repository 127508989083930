import request from "@/plugins/request";

const baseUrl = "notice";
function getmarquees(data) {
  return request({
    url: baseUrl + "/getmarquees",
    method: "POST",
    data
  });
}
function getWinmarquees(data) {
  return request({
    url: baseUrl + "/winmarquees",
    method: "POST",
    data
  });
}
function getMessageList(data) {
  return request({
    url: baseUrl + "/getmessage",
    method: "POST",
    data
  });
}
function readMessage(data) {
  return request({
    url: baseUrl + "/readmessage",
    method: "POST",
    data
  });
}
function service(data) {
  return request({
    url: baseUrl + "/service",
    method: "POST",
    data
  });
}

export const noticeApi = {
  getmarquees,
  getWinmarquees,
  getMessageList,
  readMessage,
  service
};
